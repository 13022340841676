import colors from 'vuetify/lib/util/colors'

export default {
  primary: {
    base: colors.deepPurple.accent4,
    darken1: colors.purple.darken2
  }
}

//
// light: {
//   primary: colors.purple,
//     secondary: colors.grey.darken1,
//     accent: colors.shades.black,
//     error: colors.red.accent3,
//     background: colors.indigo.lighten5 // Not automatically applied
// }