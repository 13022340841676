
<template>
  <div>
      <v-container class="complaints-container items" fluid>
          <div
              v-for='(complaint) in getAllComplaints'
              :key='complaint.id'
              :id="'homeComplaints_' + complaint.id"
              class='item'
          >
            <ComplaintCard
                :item="complaint"
                :whoseCard="'homeComplaints'"
                class=''
            />

<!--            <span v-if="isComplaintCardLoopEnd(index)">-->
<!--              <img :src='complaint.userInfo.photoURL' alt='' style='opacity: 0;'>-->
<!--            </span>-->

          </div>
      </v-container>

      <v-container>
        <v-row class='justify-center'>
          <v-btn
              elevation="2"
              rounded
              class='mb-15'
              @click='initComplaints(lastDoc)'
          >
            <v-icon left>mdi-cached</v-icon> Load More
          </v-btn>
        </v-row>
      </v-container>

<!--    <v-container v-else>-->
<!--      <v-skeleton-loader-->
<!--          v-bind="attrs"-->
<!--          type="article, actions"-->
<!--      ></v-skeleton-loader>-->
<!--      <div-->
<!--          v-for='n in 7'-->
<!--          :key='n'-->
<!--      >-->
<!--        <v-skeleton-loader-->
<!--            v-bind="attrs"-->
<!--            type="table-heading, list-item-two-line"-->
<!--        ></v-skeleton-loader>-->
<!--      </div>-->
<!--    </v-container>-->

  </div>
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ComplaintCard from '@/components/ComplaintCard'
// import PullToRefresh from 'pulltorefreshjs';

export default {
  name: 'Complaints',
  props:['companyId'],
  components:{
     ComplaintCard
  },
  data: () => ({
    loading: false,
    page: 1,
    titles: [],
    forLoopEnd : false,
    pagFlag: false,
    startPage: false,
    attrs: {
      class: 'mb-2',
      boilerplate: true,
      elevation: 0
    }
  }),
  watch:{
    companyId(){
      this.initComplaints()
    },
    isAuthDone:{
      handler(newVal, oldVal) {
        //console.log(`auth done changed: ${newVal}`);
        if(newVal){
          if(this.getAllComplaints.length > 0) return
          this.initComplaints()
        }
      },
      immediate: true

    }

  },
  computed: {
    ...mapGetters(['getAllComplaints', 'isAuthDone']),

    company_id(){
      return this.companyId
    },
    lastDoc(){
      return this.getAllComplaints[this.getAllComplaints.length - 1]
    }
  },
  methods: {
    ...mapActions(['fetchComplaints']),
    async initComplaints(param){
      if(typeof param === 'object' ){
        console.log('last doclu girdi:   ', param)

        const isFetchedComplaints = this.fetchComplaints({lastDoc: param})
        console.log(isFetchedComplaints)
        console.log(isFetchedComplaints ? "complaint fetched" : "complaint not fetched" );
        isFetchedComplaints ? this.startPage = true : false

        return
      }

      if(this.company_id){
        console.log('company id geldiii', this.company_id)

        const isFetchedComplaints = await this.fetchComplaints({companyId: this.company_id })
        console.log(isFetchedComplaints)
        console.log(isFetchedComplaints ? "complaint fetched" : "complaint not fetched" );
        isFetchedComplaints ? this.startPage = true : false


      }
      else{

        // if(!this.getAllComplaints.length > 0){


        const isFetchedComplaints = this.fetchComplaints()
        isFetchedComplaints ? this.startPage = true : false

        // }
      }
    },

    isComplaintCardLoopEnd(param){
      // console.log(param)
      // console.log(this.getAllComplaints.length)

      if(param+1 === this.getAllComplaints.length){
        //alert('gotcha')
        this.forLoopEnd = true
        return true
      }

      //return param === this.getAllComplaints.length ? this.forLoopEnd = true : false
    }
  },
  created() {
    // if(this.getAllComplaints.length > 0) return
    // // if(localStorage.firstLoad === 'false'){
    // //   //localStorage.removeItem('fetchFlag')
    // //   return
    // // }
    // this.initComplaints()
    //
    // //this.fetchData();

  },
  mounted(){
    let _self = this

    // PullToRefresh.init({
    //   mainElement: '.complaints-container',
    //   onRefresh() {
    //     _self.fetchComplaints()
    //   }
    // });

  },
  destroyed(){
    //PullToRefresh.destroyAll();
  }
};
</script>

<style>
/* .container {
    padding: 17px;    
} */

/* .complaints-container{ */
  /*background-color: #EDEEF0;*/
/* } */

#flex-container {
  height: 400px;
  max-height: 400px;
  flex-flow: column wrap;
  box-sizing: border-box;
  display: flex;
}


.items {
  -webkit-columns: 4 350px;
  -moz-columns: 4 350px;
  columns: 4 350px;
  -webkit-column-gap: 1em;
  -moz-column-gap: 1em;
  column-gap: 1em;
}

.item {
  display: inline-block;
  width: 100%;
  margin: 5px 0;
}

/*._v-container{*/
/*  height: unset !important;*/
/*  -webkit-tap-highlight-color: rgba(0,0,0,0);*/
/*  width: unset !important;*/
/*  position: initial !important;*/
/*  top: 0;*/
/*  left: 0;*/
/*  overflow: hidden;*/
/*  -webkit-user-select: none;*/
/*  -moz-user-select: none;*/
/*  -ms-user-select: none;*/
/*  -o-user-select: none;*/
/*  user-select: none;*/
/*}*/

/*._v-container>._v-content{*/
/*  transform: unset !important;*/
/*}*/



</style>
