<template>
<div>
<!--  <Header />-->
<!--  <pre>-->
<!--    {{ getAllCompanies }}-->
<!--  </pre>-->

  <v-container v-if='startPage' class="complaints-container">
    <v-row>
      <!--          <v-spacer></v-spacer>-->
      <v-col
          v-for='(company, index) in getAllCompanies'
          :key='company.id'
          :id="company.id"
          cols="12"
          sm="6"
          md="4"
          class='pb-0'
      >
      <CompanyCard
          v-if='index < 50'
          :item="company"
      />
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else>
    <v-skeleton-loader
        v-bind="attrs"
        type="article, actions"
    ></v-skeleton-loader>
    <div
        v-for='n in 7'
        :key='n'
    >
      <v-skeleton-loader
          v-bind="attrs"
          type="table-heading, list-item-two-line"
      ></v-skeleton-loader>
    </div>
  </v-container>
</div>
</template>

<script>
import Complaints from '@/components/Complaints';
import { mapActions, mapGetters, mapState } from 'vuex';
import CompanyCard from '@/components/CompanyCard';

export default {
  name: 'Companies',
  components: {
    CompanyCard
    // Header,
  },
  data: () => ({
    startPage: true,
    attrs: {
      class: 'mb-2',
      boilerplate: true,
      elevation: 0
    }
  }),
  computed:{
    ...mapGetters(['getAllCompanies']),
    ...mapState(['uiConfigs'])
  },
  methods:{
    ...mapActions(['fetchCompaniesOnSS'])
  },
  created() {
    // console.log(this.getAllComplaints.length)
    // console.log(this.getAllCompanies.length)

    //sub complaint den geri geldiginde tekrar fetch etmemesi icin
    if(!this.getAllCompanies.length > 0){
      this.fetchCompaniesOnSS()
    }

  }
};
</script>

<style scoped>

</style>