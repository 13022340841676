<template>
  <v-app class='app-main' :style="{background: $vuetify.theme.themes[theme].background}">
    <v-overlay
      opacity="0"
      :value="uiConfigs.showSpinner"
    >

      <v-progress-circular
        size="70"
        width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>

    </v-overlay>   

    <v-overlay absolute :value="uiConfigs.overlay"></v-overlay>

    <v-main>
      <Header v-if="['Home', 'Companies', 'Panel', 'MyComplaints'].includes($route.name)" />

      <transition
        name="fade"
      >
<!--        <keep-alive :include="['']">-->
          <router-view></router-view>
<!--        </keep-alive>-->
      </transition>
    </v-main>
    
    <BottomNav v-if="['Home', 'Companies', 'Company', 'Panel'].includes($route.name)" />
    <Login />
    <AddComplaint v-if="['Home', 'Companies', 'Company'].includes($route.name)" />


  </v-app>
</template>

<script>
import Header from '@/components/Header.vue';
import BottomNav from "@/components/BottomNav.vue";
import Login from "@/components/Login.vue";
import AddComplaint from '@/components/AddComplaint.vue';




import { mapActions, mapState } from 'vuex';


export default {
  name: "App",
  components: {
    Header,
    BottomNav,
    Login,
    AddComplaint

  },
  data: () => ({

  }),
  computed: {
    ...mapState(['uiConfigs']),
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  methods:{
    ...mapActions(['initFirebaseAuth'])
  },
  created() {
    //const res3 = await this.initFirebaseAuth()
    this.initFirebaseAuth()

  }

};
</script>

<style>




.btn-fix:focus::before { opacity: 0 !important; }
.btn-fix:hover::before { opacity: 0.08 !important; }

.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before { opacity: 0 !important; }

.app-main{
  /*background-color: #EDEEF0;*/


}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.remove-selected:focus::before {
  opacity: 0 !important;
}

.slither-enter-active, .slither-leave-active {
  transition: transform 1s;
}

.slither-enter, .slither-leave-to {
  transform: translateX(-100%);
}

.slither-enter-to, .slither-leave {
  transform: translateX(0);
}

.drain-enter-active, .drain-leave-active {
  transition: transform 1s;
}

.drain-enter, .drain-leave-to {
  transform: translateY(100%);
}

.drain-enter-to, .drain-leave {
  transform: translateY(0);
}

.slither-enter-active, .slither-leave-active {
  transition: transform 1s;
}

.slither-enter, .slither-leave-to {
  transform: translateX(-100%);
}

.slither-enter-to, .slither-leave {
  transform: translateX(0);
}
</style>