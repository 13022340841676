<template>
  <div class='add-complaint-main'>
    <v-row justify="center" >
      <v-dialog
          v-model="uiConfigs.addComplaintForm "
          fullscreen
          hide-overlay
          transition="scale-transition"
          origin="right bottom"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="mb-12 btn-fix"
              fab
              dark
              fixed
              right
              bottom
              color="primary"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
              dark
              color="primary"
              dense
              class='add-complaint-toolbar'
          >
            <v-btn
                icon
                dark
                @click="uiConfigs.addComplaintForm = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Make a Complain</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  dark
                  icon
                  :disabled='
                    !complaintFormData.companyInfo.name ||
                    !complaintFormData.title ||
                    !complaintFormData.detail ||
                    !agreement
                  '
                  @click.stop="complaintBeforeAdd"
              >
                POST
                <v-icon
                    dark

                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
            <v-container fluid>
              <v-row>
                <v-form class='add-complaint-form' autocomplete="disabled" ref="form">

                <v-col class='complaint-form-col-padding'>
                  <v-autocomplete
                      v-model="complaintFormData.companyInfo.name"
                      :loading="loading"
                      :items="getAllCompanies"
                      label="Please choose a company"
                      placeholder=""
                      autocomplete="disabled"
                      @blur='handleFormInputMutate()'
                      :rules="companyRules"

                  >
                    <template v-slot:selection="{ item }" >


                      <v-list-item-avatar
                          color="purple lighten-5"
                          size="30"
                      >
                        <img
                            v-if="item.logo"
                            alt="Company Logo"
                            :src="item.logo"
                        >
                        <!-- {{ item.name.charAt(0) }} -->
                      </v-list-item-avatar>
                      <v-list-item-content style="flex: unset;">
                        <v-list-item-title v-text="item.name"></v-list-item-title>

                        <!-- <v-list-item-subtitle v-text="item.field"></v-list-item-subtitle> -->
                        <v-input
                            style="display: none;"
                            ref="companyId"
                            type="hidden"
                            name="companyId"

                            hidden
                            :item-value="getInstantCompanyInfoFromFormData(item)"
                        ></v-input>
                      </v-list-item-content>

                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                          color="purple lighten-5"
                          class="headline font-weight-light white--text"
                      >
                        <img
                            v-if="item.logo"
                            alt="Company Logo"
                            :src="item.logo"
                        >
                        <!-- {{ item.name.charAt(0) }} -->
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.field"></v-list-item-subtitle>
                        <!-- <input type="hidden" name="companyId" :value="item.id"> -->
                      </v-list-item-content>
                      <!-- TODO add company icons according to company fields  -->
                      <!-- <v-list-item-action>
                        <v-icon>mdi-food</v-icon>
                      </v-list-item-action> -->
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class='complaint-form-col-padding'>
                  <v-text-field
                      v-model="complaintFormData.title"
                      @blur='handleFormInputMutate()'
                      :counter="50"
                      maxlength="50"
                      label="Complaint Title"
                      placeholder=""
                      autocomplete="disabled"
                      clearable
                      ref="b"
                      :rules="nameRules"

                  ></v-text-field>
                </v-col>
<!--                <v-card-subtitle  style=''>Tell about more details:</v-card-subtitle>-->

                <v-col class='complaint-form-col-padding'>
                  <v-textarea

                      v-model="complaintFormData.detail"
                      class="add-complaint-detail"
                      label="Complaint Detail:"
                      flat
                      @blur="handleFormInputMutate();"
                      @focus="onFocusWorks(); placeHolder=''"
                      :counter="1500"
                      no-resize
                      rows='7'
                      maxlength="1500"
                      :placeholder="placeHolder"
                      auto-grow
                      :rules="detailRules"

                  ></v-textarea>
                </v-col>
<!--                  file upload input-->
<!--                <v-col>-->
<!--                  <template>-->
<!--                    <v-file-input-->
<!--                        accept="image/png, image/jpeg, image/bmp"-->

<!--                        small-chips-->
<!--                        counter-->
<!--                        multiple-->
<!--                        show-size-->
<!--                        label="File input"-->
<!--                        :rules="fileRules"-->

<!--                    ></v-file-input>-->
<!--                  </template>-->
<!--                </v-col>-->
                <v-col>
                  <v-checkbox
                      v-model="agreement"
                      :rules="requiredRule"
                      color="primary"
                  >
                    <template v-slot:label>
                      <div @click.stop="">
                        Do you accept the
                        <a
                            href="#"
                            @click.prevent="termsDialog = true"
                        >terms</a>
                        and
                        <a
                            href="#"
                            @click.prevent="conditions = true"
                        >conditions?</a>
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>

                </v-form>
              </v-row>
            </v-container>
        </v-card>
<!--        <div class='end-page'></div>-->

      </v-dialog>
    </v-row>
    <v-dialog
        v-model="termsDialog"
        absolute
        max-width="400"
        persistent
    >
      <v-card>
        <v-card-title class="headline grey lighten-3">
          Legal
        </v-card-title>
        <v-card-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
              text
              @click="agreement = false, termsDialog = false"
          >
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="white--text"
              color="deep-purple accent-4"
              @click="agreement = true, termsDialog = false"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card :loading="dialog_1_loader">
        <v-btn
            icon
            @click="dialog = false"

            right
            absolute
            style='right: 0'
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="h5 text-truncate ">
          <v-icon large color="gray" class="mr-1">
             mdi-incognito-circle
          </v-icon>Remain anonymous?          
        </v-card-title>

        <v-card-text>
          You haven't signed-in yet but you can still submit your complaint anonymously, if you want to get updates from your complaint, you can sign-in.<br /><br />Do who wish to remain anonymous?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="deep-purple darken-1"
            text
            @click.stop="signInBtnClick"
          >
            SIGN IN
          </v-btn>

          <v-btn
            color="black darken-1"
            text
            @click.stop="complaintBeforeAddAsAnon"
          >
            YES, I'M OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="uiConfigs.snackbar"
      timeout="3000"
    >
      Thank you. Your complaint has been successfully submitted!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="uiConfigs.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import firebase from 'firebase/app';


export default {
  components: {
  },
  data: () => ({
    complaintFormData: {
      companyInfo: {
        id: 0,
        name: "",
        logo: "",
        field: ""
      },
      title: "",
      detail: ""
    },
    items: [],
    loading: false,
    search: null,
    select: null,
    fav: true,
    message: false,
    hints: true,
    dialog: false,
    dialog_1_loader: false,
    dialog33: false,
    notifications: false,
    sound: true,
    widgets: false,
    conditions: false,
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.',
    snackbar: false,
    agreement: false,
    termsDialog: false,
    placeHolder: "Give additional information about your complaint. For example when and where did you get this service",
    nameRules: [
      v => !!v || 'Title is required',
      v => (v && v.length <= 50) || 'Title must be less than 50 characters'
    ],
    companyRules: [
      v => !!v || 'Company is required'
    ],
    detailRules: [
      v => !!v || 'Complaint detail is required'
    ],
    fileRules: [
      value => !value || value.size < 2000000 || 'File size should be less than 2 MB!'
    ],
    requiredRule: [
      v => !!v || 'This field is required'
    ]
  }),
  watch: {
    menu(val){
        this.resetComplaintForm()
    },
     value(value){
      this.$emit("onchange", value);
      console.log(value)
    },
     selectedCompany(a) {
      console.log(a)
     },
    search(val) {
      //val && val !== this.getComplaintFormData.company && this.querySelections(val);
      // this.getComplaintFormData.companyInfo.id = this.getAllCompanies
      //console.log(val)
    },
    complaintFormData: {
      // Will fire as soon as the component is created
      deep: true,
      handler(complaintFormData) {
        //console.log('aa')

        //this.onFocusWorks()

        // if(complaintFormData.companyInfo.id !== 0){
        //   console.log(complaintFormData)
        //
        //   const setJsonLocalForm = JSON.stringify(complaintFormData)
        //   console.log('setting local storage complaint data', setJsonLocalForm)
        //
        //   localStorage.setItem('complaintFormData', setJsonLocalForm);
        //   //this.updateComplaintFormData(setJsonLocalForm)
        // }
       
      }
    }
    // complaintDetail(val){
    //   console.log('detail cahnged', val)
    //   this.onFocusWorks()
    // }
  },
  computed:{
    ...mapGetters(['getAllCompanies', 'getUserInfo']),
    ...mapState(['uiConfigs']),
    pageHeight () {
      return document.body.scrollHeight
    }
    // complaintDetail(){
    //   return this.complaintFormData.detail
    // }
  },
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    handleFormInputMutate(){
      if(this.complaintFormData.companyInfo.id !== 0){
        console.log(this.complaintFormData)

        const setJsonLocalForm = JSON.stringify(this.complaintFormData)
        console.log('setting local storage complaint data', setJsonLocalForm)

        localStorage.setItem('complaintFormData', setJsonLocalForm);
        //this.updateComplaintFormData(setJsonLocalForm)
      }
      if(localStorage.getItem('complaintFormData') !== false){
        this.updateComplaintFormData(localStorage.getItem('complaintFormData'))
      }
    },
    onFocusWorks(){
      console.log(this.pageHeight)
      //this.$vuetify.goTo(this.pageHeight, { duration: 0 });
      //window.scrollTo(0,document.querySelector('.add-complaint-form').scrollHeight);
      //document.querySelector('.end-page').scrollIntoView(true);
      console.log('scrolled')
      // !this.userInfo ? this.uiConfigs.loginDialog = true : false;
      // localStorage.selectedComplaintURI = this.getSelectedComplaint[0].seoUrl;
    },
    getInstantCompanyInfoFromFormData(data){
        this.complaintFormData.companyInfo.id = data.id
        this.complaintFormData.companyInfo.logo = data.logo
        this.complaintFormData.companyInfo.field = data.field
    },
    ...mapActions(
      [
        'addComplaint', 
        'fetchCompaniesOnSS',
        'updateComplaintFormDataCompanyId',
        'fetchComplaints',
        'updateComplaintFormData',
        'updateUserInfo',
        'signAnon'
      ]
    ),    
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.getAllCompanies.name.filter(e => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
        });
        this.loading = false;
      }, 500);
    },
    resetComplaintForm() {
      //this.$refs.form.reset()
      //this.$refs.companyId.value = 0
      // this.complaintFormData = {
      //   companyInfo: {
      //     id: 0,
      //     name: "",
      //     logo: ""
      //   },
      //   title: "",
      //   detail: ""
      // }
      // this.agreement = false
      this.$refs.form.resetValidation()
      this.$refs.form.reset()

    },
    // repaint() {
    //   //setTimeout(() => typeof this.$redrawVueMasonry === 'function' ? this.$redrawVueMasonry() : false, 2000);
    //   setTimeout(() => {
    //     //this.$redrawVueMasonry()
    //     console.log('redrawed for add complaint')
    //   }, 3000)
    // },
    formStatusHandler(reset){
      this.uiConfigs.addComplaintForm ? this.uiConfigs.addComplaintForm = false : this.uiConfigs.addComplaintForm = true
      this.uiConfigs.overlay ? this.uiConfigs.overlay = false : this.uiConfigs.overlay = true
      this.dialog = false
      console.log(reset)

      setTimeout(() => {
        reset ? this.resetComplaintForm() : false 

      }, 2000);
 
      // if(this.menu){
      //   this.menu = false
      // }
      // if(this.overlay){
      //   this.overlay = false
      // }
    },
    async completeComplainAdd(params) {
      this.dialog_1_loader = true
      console.log('completeComplainAdd')

      // this.complaintFormData.companyInfo.id = this.$refs.companyId.value
    
      //this.updateComplaintFormData(this.complaintFormData)  
      //const self = this

      const res = await this.addComplaint()
      
      if(res){
        this.dialog_1_loader = false
        this.formStatusHandler(true)
        await this.fetchComplaints()
        this.uiConfigs.addComplaintForm = false
        this.uiConfigs.overlay = false

        //this.repaint()

      }

      // setTimeout(() => {
      //   self.dialog_1_loader = false
      //   self.addComplaint()       

      // }, 2000);

      
    },
    async complaintBeforeAdd(){      

      if(!firebase.auth().currentUser){
        this.dialog = true
      }
      else{        
       await this.completeComplainAdd()
      }

      
    },
    getSelectedCompanyId(a){
      alert(a)
    },

    async complaintBeforeAddAsAnon(){
      console.log('complaintBeforeAddAsAnon')
        const isAnonSigned = await this.signAnon()
        console.log('isAnonSigned', isAnonSigned)
        if(isAnonSigned){
         const res = await this.completeComplainAdd()
          console.log('completeComplainAdd in annon', res)

        }
        else{
          console.log('annon signed error')
        }
         
    },
    signInBtnClick(){
      //localStorage.setItem('pendingAddComplaint', true)
      localStorage.pendingAddComplaint = true
      this.uiConfigs.pendingAddComplaint = true //this is for cordova app mobile
      this.dialog = false 
      this.uiConfigs.loginDialog = true
    }
  },
  created() {
    if(localStorage.complaintFormData){
        this.updateComplaintFormData(localStorage.getItem('complaintFormData'))
    }
    else{
      localStorage.complaintFormData = false
    }
    //this.fetchCompaniesOnSS();
  },
  mounted(){
  }
};
</script>

<style>
  .add-complaint-detail textarea{
    margin-bottom: 10px !important;
    padding-top: 7px !important;
    line-height: 1.2rem !important;
  }
  /*.theme--light.v-input input, .theme--light.v-input textarea {*/
  /*  line-height: 1.2rem;*/
  /*}*/


  /*.v-textarea textarea {*/
  /*  align-self: stretch;*/
  /*  flex: 1 1 auto;*/
  /*  line-height: 1.2rem;*/
  /*  max-width: 100%;*/
  /*  min-height: 32px;*/
  /*  outline: none;*/
  /*  padding: 0;*/
  /*  width: 100%;*/
  /*}*/
  /* .add-complaint-main textarea { */
    /*height: calc(100vh - 302px);*/
  /* } */

  .add-complaint-toolbar{
    z-index: 9999;
    width: 100%;
    position: fixed;
  }
  .add-complaint-form{
    padding-top: 48px;
    width: 100%;
    /*min-height: 1100px;*/
  }
  .end-page{
    position: fixed;
    bottom: 0;
    height: 10px;
    width: 100%;
    background-color: antiquewhite;
  }
  .complaint-form-col-padding{
    margin-bottom: -16px !important;
  }

  .v-dialog--fullscreen {  
    z-index: 999999 !important;
}
</style>

