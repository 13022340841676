<template>
  <v-card
      class="mx-auto"
      max-height='300'
      max-width="900"
      color='#952175'
      dark
  >
    <v-container>
      <v-row
          align="end"
          class="pl-3 pt-3"
      >
        <v-col>
          <v-avatar
              class="profile"
              color="white"
              size="140"
          >
            <v-img :src="company.logo"></v-img>
          </v-avatar>
        </v-col>
        <v-col>
          <h1>{{ company.stats.complaints }}</h1>
        </v-col>


      </v-row>

      <v-row class='pt-0'>
          <v-list-item
              color="rgba(0, 0, 0, .4)"
              dark
          >
            <v-list-item-content>
              <v-list-item-title class="title">
                {{company.name}}
              </v-list-item-title>
              <v-list-item-subtitle>{{company.field}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
      </v-row>
    </v-container>

  </v-card>
</template>

<script>
export default {
  name: 'CompanyProfile',
  props:['company']
};
</script>

<style scoped>

</style>