<template>
  <div>
      <h1>Profile</h1>


  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapState } from 'vuex';

import Complaints from '@/components/Complaints.vue';
import AddComplaint from '@/components/AddComplaint.vue';
import Login from "@/components/Login.vue";

// import Header from '@/components/Header.vue';
// import BottomNav from "@/components/BottomNav.vue";


export default {
  name: 'Profile',
  components: {
    // eslint-disable-next-line
    Login
    // BottomNav
  },
  computed:{
    ...mapGetters(['getAllCompanies']),
    ...mapState(['uiConfigs'])
  },
  methods:{
    ...mapActions(['fetchCompaniesOnSS'])
  },
  created() {
    // console.log(this.getAllComplaints.length)
    // console.log(this.getAllCompanies.length)

    //sub complaint den geri geldiginde tekrar fetch etmemesi icin
    if(!this.getAllCompanies.length > 0){
      this.fetchCompaniesOnSS()
    }

  },
  mounted() {
    //this.uiConfigs.showSpinner = false

  }
};
</script>
<style scoped>

</style>
