<template>
  <div>
    <v-card
        class='complaint-comment-card mb-1'
        flat
        tile
    >
      <v-card-subtitle v-if='item.userInfo.displayName' class='pt-2' style='zoom:0.9'>
        <v-avatar size='20' style='vertical-align: sub;'>
          <v-img
              v-if='item.userInfo.photoURL'
              :src='item.userInfo.photoURL'
              class='elevation-6'
          ></v-img>
        </v-avatar>

        {{ item.userInfo.displayName | truncateComplaintDetail(30, '...') }} - <span
          class='text--disabled'>{{ item.fromNow }}</span>

      </v-card-subtitle>

      <v-card-text v-if='item.commentText' class='text--primary'>

        <div v-show='item.show || item.commentText.length < 120' @click='item.show = false'>
          {{ item.commentText }}
          <v-icon v-if='item.commentText.length > 120'>{{ item.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </div>

        <div v-show='!item.show && item.commentText.length > 120' @click='item.show = true'>
          {{ item.commentText | truncateComplaintDetail(121, '... Read more') }}
<!--          <v-btn dense small text>Read More-->
<!--            <v-icon>{{ comment.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
<!--          </v-btn>-->
        </div>

      </v-card-text>
<!--      <v-divider></v-divider>-->
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class='mt-7' icon absolute top right v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if='userInfo != null && userInfo.id === item.userInfo.id' @click='deleteComplaintComment(item.id)'>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-title>Report</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'ComplaintCommentCard',
  props: ['sub', 'item'],
  computed: {
    ...mapState(['userInfo', 'uiConfigs'])
  },
  filters: {
    truncateComplaintDetail: function(text, length, suffix) {
      //console.log(text, length, suffix)
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  },
  methods: {
    ...mapActions(['deleteComplaintComment'])
  }
};
</script>

<style scoped>
.complaint-comment-card .v-btn--absolute.v-btn--top, .v-btn--fixed.v-btn--top {
  top: 4px;
}
.complaint-comment-card .v-btn--absolute.v-btn--right, .v-btn--fixed.v-btn--right {
  right: 4px;
}

</style>