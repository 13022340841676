import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import Complaint from "../views/Complaint.vue";
import MyComplaints from "../views/MyComplaints.vue";
import Companies from "@/views/Companies.vue";
import Company from "@/views/Company.vue";
import Panel from "@/views/Panel.vue";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { reuse: true }
  },
  {
    path: "/companies",
    name: "Companies",
    component: Companies
  },
  {
    path: "/my-complaints",
    name: "MyComplaints",
    component: MyComplaints
  },
  {
    path: '/companies/:seoUrl-_-:id',
    name: "Company",
    component: Company
  },
  {
    path: "/panel",
    name: "Panel",
    component: Panel
  },
  {
    path: '/complaint/:seoUrl',
    name: "Complaint",
    component: Complaint
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  // mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      //console.log('if saved position:')
      //console.log('to:',to)
      //console.log('from:',from)
      //console.log('saved:',savedPosition)
      return savedPosition;
    } else {
      // console.log('no saved position:')
      // console.log('to:',to)
      // console.log('from:',from)
      // console.log('saved:',savedPosition)
      const position = {}
      // if(to.hash){
      //   position.selector = to.hash
      //   if(document.querySelector(to.hash)){
      //     return position
      //   }
      //   return false
      // }
      return { x: 0, y: 0 };
    }
  },
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash
  //     };
  //   }
  // },
  routes
});


// router.beforeEach((to, from, next) => {
//   store.state.uiConfigs.showSpinner = true
//   //alert('burda before')
//
//  next()
//
// })
//
// router.afterEach((to, from) => {
//   // store.state.uiConfigs.showSpinner = false
//   // alert('burda after')
//
//
// })

// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//     // Start the route progress bar.
//     // eslint-disable-next-line
//     //NProgress.start()
//     store.state.uiConfigs.showSpinner = true
//     console.log(store)
//   }
//   next()
// })

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  // eslint-disable-next-line
 // NProgress.done()
  store.state.uiConfigs.showSpinner = false

})



export default router;
