<template>
  <div>
    <v-container style='background-color: white !important;'>
      <h3>Add Company</h3>

      <v-row
          justify="space-between"
      >
        <v-col
            cols="12"
            md="4"
        >
          <v-form
              ref="form"
              v-model="valid"
          >
            <v-text-field
                v-model="companyInfo.name"
                label="Company Name"
                prepend-icon="mdi-domain"

                clearable
            ></v-text-field>

<!--            <v-text-field-->
<!--                v-model="companyInfo.field"-->
<!--                label="Field"-->
<!--                prepend-icon="mdi-tag"-->

<!--                clearable-->

<!--            ></v-text-field>-->

            <v-combobox
                v-model="companyInfo.field"
                auto-select-first
                hide-details
                hide-no-data
                clearable
                :items="companyFields"
                prepend-icon="mdi-tag"

                label="Field"
                class='mb-10'
            ></v-combobox>

            <!--          <v-text-field-->
            <!--              v-model="companyInfo.logo"-->
            <!--              label="Logo"-->
            <!--              clearable-->

            <!--          ></v-text-field>-->




<!--            <v-text-field-->
<!--                class='pt-9'-->
<!--                v-model="companyInfo.website"-->
<!--                label="Website"-->
<!--                prepend-icon="mdi-web"-->

<!--                clearable-->

<!--            ></v-text-field>-->

            <!--          <v-file-input-->
            <!--              v-model="companyInfo.logo"-->
            <!--              :rules="rules"-->
            <!--              accept="image/*"-->
            <!--              show-size-->
            <!--              small-chips-->
            <!--              placeholder="Pick a logo"-->
            <!--              prepend-icon="mdi-camera"-->
            <!--              label="Logo"-->
            <!--          ></v-file-input>-->


            <div class="text-center">
              <croppa v-model="myCroppa"
                      :accept="'image/*'"
                      initial-size="contain"
                      placeholder="Click to add logo"
                      :canvas-color="'#ffffff'"
                      :placeholder-font-size="14"
                      :prevent-white-space="false"
                      :width="300"
                      :height="300"
                      :quality="1"
              >
              </croppa>
            </div>


            <div class="text-center mt-10">
              <v-btn
                  color="error"
                  @click="reset"
                  class='mr-10'
              >
                Clear
              </v-btn>
              <v-btn
                  color="success"
                  @click="beforeAddCompany()"
              >
                Add
              </v-btn>
            </div>

          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
        v-model="uiConfigs.snackbar"
        timeout="4000"
    >
      Company Added!

      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="uiConfigs.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';


export default {
  name: 'AddCompany',

  data: () => ({
    valid: true,
    allowSpaces: false,
    match: 'Foobar',
    max: 0,
    myCroppa: {},
    model: 'Foobar',
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!'
    ],
    companyFields:[
        'Telecommunications',
        'Food & Restaurant',
        'Grocery',
        'Clothing',
        'Mail & Logistics',
        'Furniture',
        'Home Improvement',
        'Electronics',
        'SPA & Massage'
    ],
    companyInfo: {
      name:"",
      field:"",
      logo:null,
      website:"",
      country: "Canada"
    }
  }),
  computed: {
    ...mapState(['uiConfigs'])

  },
  watch: {
  },
  methods: {
    ...mapActions(['addCompany', 'uploadFile']),
    reset () {
      this.$refs.form.reset()
    },
    async beforeAddCompany(){
      this.uiConfigs.showSpinner = true
      this.companyInfo.logo = this.myCroppa.generateDataUrl('image/jpeg', 0.3)
      const res = await this.addCompany(this.companyInfo)
      this.uiConfigs.showSpinner = false
      this.uiConfigs.snackbar = true
      this.reset()
      this.myCroppa.remove()

    }
  }

}
</script>

<style scoped>
.croppa-container {
text-align: center;
  border: 1px solid gray;
}

</style>