<template>
    <div>
        <v-dialog
            eager
            v-model="uiConfigs.loginDialog"
            fullscreen
            transition="dialog-bottom-transition"
            persistent
            class='login-dialog'
            hide-overlay



        >
          <v-card>
            <v-toolbar
                dark
                color="primary"
            >
              <v-btn
                  icon
                  dark
                  @click="uiConfigs.loginDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Sign-in</v-toolbar-title>
              <!--            <v-spacer></v-spacer>-->
              <!--            <v-toolbar-items>-->
              <!--              <v-btn-->
              <!--                  dark-->
              <!--                  text-->
              <!--                  @click="uiConfigs.loginDialog = false"-->
              <!--              >-->
              <!--                Save-->
              <!--              </v-btn>-->
              <!--            </v-toolbar-items>-->
            </v-toolbar>

            <!--                <v-card-title class="headline ">-->
<!--                Sign-in-->
<!--                </v-card-title>-->

                <v-card-text class='mt-10'>
                    <div id="firebaseui-auth-container"></div>              

                </v-card-text>

<!--                <v-card-actions>-->
<!--                    &lt;!&ndash; <v-spacer></v-spacer> &ndash;&gt;-->

<!--                    <v-btn-->
<!--                        color="black darken-1"-->
<!--                        text-->
<!--                        @click.stop="uiConfigs.loginDialog = false"-->
<!--                    >-->
<!--                        Close-->
<!--                    </v-btn>-->

<!--                    <v-btn-->
<!--                        color="deep-purple darken-1"-->
<!--                        text-->
<!--                        @click="uiConfigs.loginDialog = false"-->
<!--                    >-->
<!--                        YES, SIGN IN 2-->
<!--                    </v-btn>-->
<!--                </v-card-actions>-->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'


    export default {
        props:[         
        ],
        data: () => ({
        }),
        computed:{
            ...mapState(['uiConfigs'])
        },
        watch: {
          uiConfigs: {
            handler(val){
              // do stuff
              if(val.loginDialog){
                document.documentElement.style.overflow = 'hidden';

              }
              else{
                document.documentElement.style.overflow = '';

              }
            },
            deep: true
          }
        },
        methods:{
            ...mapActions(
                [
                    'addComplaint', 
                    'updateUserInfo',
                    'loginUIinit'
                ]
            )
        },
        mounted(){
            this.loginUIinit()
            //ui.start('#firebaseui-auth-container', this.getUiConfig())
        }
    }
</script>

<style>
.login-dialog{
  overflow: hidden;
}
</style>