<template>
  <div class='complaint-main'>
    <v-app-bar app color="deep-purple accent-4" dark>
<!--      <router-link to="/#qBHh1jzC8H7jTD7KG1k5">Back</router-link>-->

      <v-btn icon link @click="goBack" >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>Complaint</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon  v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click='deleteComplaint(item.id)'>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <div id='complaint-card-with-comment' v-if='startPage'>
      <ComplaintCard
          v-if="getSelectedComplaint[0]"
          :sub="true"
          :item="getSelectedComplaint[0]"
      />

      <div v-if='typeof getSelectedComplaint[0] !== "undefined" && getSelectedComplaint[0].comments.length > 0' class='complaint-comments-area'>
        <div
            v-for='(comment, index) in getSelectedComplaint[0].comments'
            :key='comment.id'
        >
          <ComplaintCommentCard
              :id="comment.id + '_' + index"
              :item="comment"
          />
        </div>
      </div>

      <div v-else class="pa-10 text-center">- No comments -</div>
    </div>
    <div v-else>
      <v-skeleton-loader
          v-bind="attrs"
          type="article, actions"
      ></v-skeleton-loader>
      <div
        v-for='n in 7'
        :key='n'
      >
        <v-skeleton-loader
            v-bind="attrs"
            type="table-heading, list-item-two-line"
        ></v-skeleton-loader>
      </div>
    </div>
    <AddComplaintComment />
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ComplaintCard from '@/components/ComplaintCard'
import ComplaintCommentCard from '@/components/ComplaintCommentCard'
import AddComplaintComment from '@/components/AddComplaintComment'
import PullToRefresh from 'pulltorefreshjs';

export default {
    name: 'Complaint',
    components:{
      ComplaintCommentCard,
      ComplaintCard,
      AddComplaintComment
    },
    data: () => ({
      startPage: false,
      attrs: {
        class: 'mb-2',
        boilerplate: true,
        elevation: 0
      }
    }),
    mounted() {
      let _self = this
      //window.addEventListener('scroll', e => this.handleNavigation(e));
      // PullToRefresh.init({
      //   mainElement: '.complaint-main',
      //   onRefresh() {
      //     console.log('test')
      //     const res = _self.fetchSelectedComplaint(this.$route.params.id)
      //     console.log(res ? "complaint fetched" : "complaint not fetched" );
      //   }
      // });
    },
    watch: {
      async $route (to, from){
        if(from.name === 'Home'){
          // const res = await this.fetchSelectedComplaint(this.$route.params.id)
          //console.log(res ? "complaint fetched route" : "complaint not fetched" );
        }
      }
    },
    methods:{
      ...mapActions(['fetchSelectedComplaint', 'goBack']),
      cleanComplaint(){
        this.$destroy();
        //this.$el.parentNode.removeChild(this.$el);
      }
    },
    beforeMount () {
    },
    async created() {
      //this.complaint[0] = this.getAllComplaints[this.$route.params.index]
      // let idFromUrl = window.location.href.split('-_-')[1]
      // console.log('id from url: ', idFromUrl)
      const complaintId = this.$route.params.id || window.location.href.split('-_-')[1]
      const res = await this.fetchSelectedComplaint(complaintId)
      console.log(res ? "complaint fetched" : "complaint not fetched" );
      res ? this.startPage = true : false
    },
    computed: {
        ...mapGetters(['getSelectedComplaint'])
    },
    destroyed() {
      //PullToRefresh.destroyAll();
      // this.$destroy();
      // this.$el.parentNode.removeChild(this.$el);


    }
}
</script>

<style>
.complaint-main{
  /*background-color: #d3d3d3b3;*/
  /*background-color: #EDEEF0;*/

}
.complaint-comments-area{
  padding-bottom: 86px;
}
</style>