<template>
<div>
  <v-container fluid v-if='getUserInfo'>

      <v-row justify='center'>
      <v-avatar class='my-5'>
        <v-img :src="getUserInfo.photoURL"></v-img>
      </v-avatar>
    </v-row>

      <h4 class='text-center'>{{getUserInfo.displayName}}</h4>
      <h5 class='text-center'>{{getUserInfo.email}}</h5>
      <h5 class='text-center'>Complaints: {{getUserInfo.stats.complaints}}</h5>


  </v-container>
  <v-container class="complaints-container items" fluid>
    <div
        v-for='(complaint, index) in getUserComplaints'
        :key='complaint.id'
        :id="'myComplaints_' + complaint.id"
        class='item'
    >
      <ComplaintCard
          :item="complaint"
          :whoseCard="'myComplaints'"
          class=''
      />

      <!--            <span v-if="isComplaintCardLoopEnd(index)">-->
      <!--              <img :src='complaint.userInfo.photoURL' alt='' style='opacity: 0;'>-->
      <!--            </span>-->

    </div>
  </v-container>

  <v-container v-if='
  getUserInfo.stats.complaints > 5
   && getUserInfo.stats.complaints !== getUserComplaints.length'>
    <v-row class='justify-center'>
      <v-btn
          elevation="2"
          rounded
          class='mb-15'
          @click='fetchUserComplaints({ startAfter: lastComplaint })'
      >
        <v-icon left>mdi-cached</v-icon> Load More
      </v-btn>
    </v-row>
  </v-container>

</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import ComplaintCard from '@/components/ComplaintCard'

export default {
  name: 'MyComplaints.vue',
  components: {
    ComplaintCard
  },
  computed: {
    ...mapGetters(['getUserInfo', 'getUserComplaints']),
    ...mapState(['uiConfigs']),
    lastComplaint(){
      return this.getUserComplaints[this.getUserComplaints.length - 1]
    }
  },
  methods:{
    ...mapActions(['fetchUserComplaints'])
  },
  created() {
    setTimeout(() =>{
      if(this.getUserInfo){
        this.fetchUserComplaints({startAfter: null})

      }
    },2000)

  }
};
</script>

<style scoped>

</style>