import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";


import store from "./store";
import vuetify from '@/plugins/vuetify/vuetify';
import 'vue-croppa/dist/vue-croppa.css'


import VueScroller from 'vue-scroller'

import Croppa from 'vue-croppa'

Vue.use(Croppa)



Vue.use(VueScroller)


//import { VueMasonryPlugin } from "vue-masonry";

// var VueMasonryPlugin = window["vue-masonry-plugin"].VueMasonryPlugin
// Vue.use(VueMasonryPlugin);


// import mystyle from '@/assets/css/style.css'
// Vue.use(mystyle)


// if(window.cordova){
//   //add deviceready event to start app
//   console.log(window.cordova);
//   alert('cordova appa')


// } else {
//   //call starting function
//   console.log('no cordovaa');
// }


Vue.config.productionTip = false;




new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");


//document.addEventListener('deviceready', app.init);


Vue.prototype.$sleeper = milliseconds => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

Vue.prototype.$toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


