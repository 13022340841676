<template>
  <div>
    <v-bottom-navigation
        app
        dense
        fixed
        color="primary"
        grow
    >
<!--      <v-btn dense @click='clickToGo("home")'>-->
      <v-btn dense :to="{ path: '/'}" >
        <!-- <span :class="{ 'd-none': hideBottomNavIcons }">Home</span> -->
        <!-- <span>Home</span> -->

        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn dense :to="{ path: '/companies'}" >
        <!-- <span :class="{ 'd-none': hideBottomNavIcons }">Home</span> -->
        <!-- <span>Company Chart</span> -->

        <v-icon>mdi-domain</v-icon>
      </v-btn>

      <v-btn dense >
        <!-- <span :class="{ 'd-none': hideBottomNavIcons }">Home</span> -->
        <!-- <span>Notifications</span> -->

        <v-icon>mdi-bell</v-icon>
      </v-btn>

    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: 'BottomNav',
  data() {
    return {
      value: 1,
      scrollCount: 0,
      offsetTop: 0,
      prev: 0,
      hideBottomNavIcons: false,
      bottomNavHeight : 56
    };
  },
  methods:{
    clickToGo(param){
      this.$router.push(param)
    },
    handleScroll (a) {
      console.log(this.scrollCount)
      this.scrollCount++
      if(this.scrollCount === 20){
        console.log(a)
      }
    },
    onScroll (e) {
      //this.offsetTop = e.target.scrollTop
      console.log(e)
      console.log(window.scrollY);
    },
    handleNavigation (e) {
      const window = e.currentTarget;

      if (this.prev > window.scrollY) {
        //console.log("scrolling up");
        this.hideBottomNavIcons = false
        this.bottomNavHeight = 56
      } else if (this.prev < window.scrollY) {
        //console.log("scrolling down");
        this.hideBottomNavIcons = true
        this.bottomNavHeight = 38
      }
      this.prev = window.scrollY;
    }
  }

};
</script>

<style scoped>
.theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  opacity: 0 !important;
}
</style>