import firebase from '@/firebase'

const db = firebase.firestore()

const complaintsRef = db.collection('complaints')
const companiesRef = db.collection('companies')
const usersRef = db.collection('users')
const fcmTokensRef = db.collection('fcmTokens')


firebase.firestore().enablePersistence()
  .catch(function(err) {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
          console.log('failed-precondition', err)
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
          console.log('unimplemented', err)

      }
  });

export { complaintsRef, companiesRef, usersRef, fcmTokensRef }
