<template>
  <div>
    <v-tabs
        dark
        background-color="primary"
        show-arrows
    >
      <v-tabs-slider color="teal lighten-3"></v-tabs-slider>

      <v-tab link to='/panel'>
        Add Company
      </v-tab>
      <v-tab>
        List Companies
      </v-tab>
      <v-tab>
        App Settings
      </v-tab>
      <v-tab>
        Add Company
      </v-tab>
      <v-tab>
        Add Company
      </v-tab>
    </v-tabs>
    <AddCompany />
    <GrantModeratorRole />
  </div>

</template>

<script>
import AddCompany from "@/components/panel/AddCompany.vue";
import GrantModeratorRole from "@/components/panel/GrantModeratorRole";

export default {
  name: 'Panel',
  components:{
    AddCompany,
    GrantModeratorRole
  }
};
</script>

<style scoped>

</style>