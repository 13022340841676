<template>
  <div>
<!--        <keep-alive>-->

<!--        </keep-alive>-->

    <Complaints />

<!--      <BottomNav />-->



  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapState } from 'vuex';

import Complaints from '@/components/Complaints.vue';

// import Header from '@/components/Header.vue';
// import BottomNav from "@/components/BottomNav.vue";


export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line
    Complaints
    // BottomNav
  },
  computed:{
    ...mapGetters(['getAllCompanies']),
    ...mapState(['uiConfigs'])
  },
  methods:{
    ...mapActions(['fetchCompaniesOnSS'])
  },
  created() {
    // console.log(this.getAllComplaints.length)
    // console.log(this.getAllCompanies.length)

    //sub complaint den geri geldiginde tekrar fetch etmemesi icin
    if(!this.getAllCompanies.length > 0){
      this.fetchCompaniesOnSS()
    }

  },
  mounted() {
    //this.uiConfigs.showSpinner = false

  }
};
</script>
<style>
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot  textarea {
  line-height: 17px;
}
</style>
