import Vue from 'vue';
import Vuex from 'vuex';
import { complaintsRef, companiesRef, fcmTokensRef, usersRef } from '@/db';
// import { storageRef } from '@/storage';
import router from '@/router';
import moment from 'moment';
import { firebaseui, ui } from '@/firebaseUI'
import firebase from '@/firebase';



//import { VueMasonryPlugin } from "vue-masonry";

// var VueMasonryPlugin = window["vue-masonry-plugin"].VueMasonryPlugin
// Vue.use(VueMasonryPlugin);

Vue.use(Vuex);

//http://heybroapp.com/myService/service.php s


export default new Vuex.Store({
  state: {
    complaintFormData: {
      companyInfo: {
        id: "",
        name: "",
        logo: ""
      },
      title: "",
      detail: ""      
    },
    complaints: [],
    companies: [],
    userInfo: null,
    selectedComplaint: [],
    selectedCompanyWithComplaints: [],
    userComplaints: [],
    appDomain: 'https://a-complaintsapp.web.app/#/',
    isAuthDone : false, // it controls auth operation state, when auth operation complated it returns true
    uiConfigs: {
      isCordova: window.cordova ? true : false,
      showSpinner: false,
      loginDialog: false,
      menuDrawer: false,
      pendingAddComplaintCommentFirstBack: false,
      addComplaintForm: false,
      overlay: false,
      snackbar: false,
      pullToRefreshSettings: {
        pullText: 'Loading...', // The text is displayed when you pull down
        triggerText: "Loading...", // The text that appears when the trigger distance is pulled down
        loadingText: 'Loading...', // The text in the load
        doneText: '', // Load the finished text
        failText: 'Error loading data', // Load failed text
        loadedStayTime: 400, // Time to stay after loading ms
        stayDistance: 50, // Trigger the distance after the refresh
        triggerDistance: 70 // Pull down the trigger to trigger the distance
      }
    }
  },
  mutations: {
    updateCompanyId (state, id) {
      state.complaintFormData.companyInfo.id = id
    },
    setComplaintFormData (state, payload) {
      state.complaintFormData = payload
    },
    setUserInfo (state, payload) {
      state.userInfo = payload
    },    
    setComplaints: (state, payload) => {
      if(state.complaints.length > 1){
        let union = [...state.complaints, ...payload];

        state.complaints = union
        console.log('aaa', state.complaints)

      }
      else{
        state.complaints = payload;

      }
    },
    setSelectedComplaint: (state, payload) => {
      state.selectedComplaint = payload;
    },
    setCompanies: (state, payload) => {
      state.companies = payload;
    },
    setSelectedCompanyWithComplaints: (state, payload) => {
      // if(state.selectedCompanyWithComplaints){
      //   //let union = [...state.selectedCompanyWithComplaints, ...payload.complaints];
      //
      //   state.selectedCompanyWithComplaints = union
      //   console.log('selectedCompanyWithComplaints mutate', state.selectedCompanyWithComplaints)
      //
      // }
      // else{
      //   state.selectedCompanyWithComplaints = payload;
      //
      // }
      state.selectedCompanyWithComplaints = payload;
    },
    setUserComplaints: (state, payload) => {
      state.userComplaints = payload;
    },
    mutateComplaintFormDataCompanyId: (state, payload) => {
      state.complaintFormData.companyInfo.id = payload;
    },
    updateAuthStatus(state, isAuthDone) {
      Vue.set(state, 'isAuthDone', isAuthDone);
    }
  },
  actions: {
    //asyncronous
    goBack({state}) {
      //router.push('/')
      const isFirstGoBackBeforeAuthChange = localStorage.getItem('pendingAddComplaintCommentFirstBack')

      console.log('pendingAddComplaintCommentFirstBack',isFirstGoBackBeforeAuthChange )
      if(isFirstGoBackBeforeAuthChange){
        localStorage.removeItem('pendingAddComplaintCommentFirstBack')
        window.location.replace('/')
        //window.history.go(-3)
      }else{
        window.history.length > 1 ? router.go(-1) : window.location.replace('/')
      }



    },
    async fetchComplaintsOnSS({ commit }) {
      complaintsRef.orderBy('createdAt', 'desc').limit(10).onSnapshot(snapshot => {
        const data = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          fromNow: moment( doc.data().createdAt.toDate()).fromNow(),
          show: false
        }));
        console.log('complaints from firestire', data);
        commit('setComplaints', data);
      });
    },
    async fetchCompaniesOnSS({ commit }) {
      companiesRef.orderBy('stats.complaints', 'desc').onSnapshot(snapshot => {
        const data = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          text: doc.data().name,
          value: doc.data().name
        }));
        commit('setCompanies', data);
      });
    },
    async fetchSelectedCompanyWithComplaints({commit, state}, payload){
      console.log('fetchSelectedCompanyWithComplaints entered')
      const res = await companiesRef.doc(payload.companyId).get()
      if(res.data().stats.complaints != 0){
        let companyComplaints
        if(payload.startAfter !== null){

          companyComplaints = await complaintsRef
            .where("status", "==", 0 )
            .where("companyInfo.id", "==", payload.companyId )
            .orderBy('createdAt', 'desc')
            .startAfter(payload.startAfter.createdAt)
            .limit(5)
            .get();
        }
        else{
          companyComplaints = await complaintsRef
            .where("status", "==", 0 )
            .where("companyInfo.id", "==", payload.companyId )
            .orderBy('createdAt', 'desc')
            .limit(5)
            .get();
        }

        const selectedCompanyComplaints = companyComplaints.docs.map(doc => ({
          ...doc.data(),
          //fromNow: doc.data().createdAt.toDate(),
          fromNow: moment( doc.data().createdAt.toDate()).fromNow(),
          show: false,
          supportBtnState: false,
          isUserSupported: state.userInfo && doc.data().supportedUsers ? (doc.data().supportedUsers.includes(state.userInfo.id) ? true : false) : false

        }));
        let companyComplaintsArr
        if(payload.startAfter !== null){
          let union = [...state.selectedCompanyWithComplaints.complaints, ...selectedCompanyComplaints];
          companyComplaintsArr = {
            ...res.data(),
            complaints: union
          }
        }
        else{
          companyComplaintsArr = {
            ...res.data(),
            complaints: selectedCompanyComplaints
          }
        }

        commit('setSelectedCompanyWithComplaints', companyComplaintsArr)
      }
      else{
        commit('setSelectedCompanyWithComplaints', res.data())
      }



    },
    async fetchComplaints({ context, commit, state }, payload) {
        //state.uiConfigs.showSpinner = true
        //console.log('filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>filter >>>>>>>', payload)
        let res

        if(typeof payload !== 'undefined' && payload.lastDoc){
          console.log('last doc lu', payload.lastDoc.createdAt)
          res = await complaintsRef
            .where("status", "==", 0 )
            .orderBy('createdAt', 'desc')
            .startAfter(payload.lastDoc.createdAt)
            .limit(5)
            .get()
        }
        else{
          state.complaints = []
          res = await complaintsRef
            .where("status", "==", 0 )
            .orderBy('createdAt', 'desc')
            .limit(5)
            .get()
        }

        // console.log(res)

        if (res.empty) {
          console.log('No such document!');
          return false
        } 

        const data = res.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          //fromNow: doc.data().createdAt.toDate(),
          fromNow: moment( doc.data().createdAt.toDate()).fromNow(),
          show: false,
          supportBtnState: false,
          isUserSupported: state.userInfo && doc.data().supportedUsers ? (doc.data().supportedUsers.includes(state.userInfo.id) ? true : false) : false

        }));
        //console.log("complaints fetched and data constructed ", data);
        commit('setComplaints', data);
        //state.uiConfigs.showSpinner = false
        //this.dispatch('reDrawMasonUI')
        localStorage.setItem('complaintsFirstFetch', 'true')

      return true

    },
    async fetchUserComplaints({ context, commit, state }, payload) {
      //console.log('fetchSelectedCompanyWithComplaints entered')
      var retrievedObject = localStorage.getItem('userInfo');
      const userInfo = JSON.parse(retrievedObject)

      console.log('retrievedObject: ', JSON.parse(retrievedObject));

      const res = await usersRef.doc(userInfo.id).get()
      const resData = res.data().stats.complaints
      userInfo.stats.complaints = resData
      this.dispatch("updateUserInfo", userInfo)
      localStorage.setItem('userInfo', JSON.stringify(userInfo));

      if(resData != 0) {

        let userComplaints
        if (payload.startAfter !== null) {

          console.log(payload)

          userComplaints = await complaintsRef
            .where("status", "==", 0)
            .where("userInfo.id", "==", userInfo.id)
            .orderBy('createdAt', 'desc')
            .startAfter(payload.startAfter.createdAt)
            .limit(5)
            .get();
        } else {
          userComplaints = await complaintsRef
            .where("status", "==", 0)
            .where("userInfo.id", "==", userInfo.id)
            .orderBy('createdAt', 'desc')
            .limit(5)
            .get();
        }

        const selectedUserComplaints = userComplaints.docs.map(doc => ({
          ...doc.data(),
          //fromNow: doc.data().createdAt.toDate(),
          fromNow: moment(doc.data().createdAt.toDate()).fromNow(),
          show: false,
          supportBtnState: false,
          isUserSupported: userInfo && doc.data().supportedUsers ? (doc.data().supportedUsers.includes(userInfo.id) ? true : false) : false

        }));

        if (payload.startAfter !== null) {
          commit('setUserComplaints', [ ...state.userComplaints, ...selectedUserComplaints ])
          return
        }

        commit('setUserComplaints', selectedUserComplaints)
      }


    },
    async fetchSelectedComplaint({ context, commit, state }, id) {
        //state.uiConfigs.showSpinner = true
        
      const selectedComplaintWithoutComments = await complaintsRef.doc(id).get()

      if (!selectedComplaintWithoutComments.exists || selectedComplaintWithoutComments.empty) {
        console.log('No selectedComplaintWithoutComments document!');
        return false
      }

      try {
        const comments = await complaintsRef
          .doc(id)
          .collection("comments")
          .orderBy('createdAt', 'asc')
          .get()
        const selectedComplaintComments = await comments.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          fromNow: moment( doc.data().createdAt.toDate()).fromNow(),
          show: false
        }));

        const selectedComplaintWithComments = {
          id: selectedComplaintWithoutComments.id,
          ...selectedComplaintWithoutComments.data(),
          fromNow: moment( selectedComplaintWithoutComments.data().createdAt.toDate()).fromNow(),
          show: false,
          supportBtnState: false,
          isUserSupported: state.userInfo && selectedComplaintWithoutComments.data().supportedUsers ? (selectedComplaintWithoutComments.data().supportedUsers.includes(state.userInfo.id) ? true : false) : false,
          comments: selectedComplaintComments
        }
        console.log(selectedComplaintWithComments)
        commit('setSelectedComplaint', [selectedComplaintWithComments])

        return true
      }catch (e) {
        console.error("Error getting document: ", e);
        return false
      }




      // complaintsRef.doc(id).collection("comments").orderBy('createdAt', 'asc').get()
      //   .then(async (_comments) => {
      //
      //     const selectedComplaintComments = await _comments.docs.map(doc => ({
      //       id: doc.id,
      //       ...doc.data(),
      //       fromNow: moment( doc.data().createdAt.toDate()).fromNow(),
      //       show: false
      //     }));
      //
      //     const selectedComplaintWithComments = {
      //       id: selectedComplaintWithoutComments.id,
      //       ...selectedComplaintWithoutComments.data(),
      //       fromNow: moment( selectedComplaintWithoutComments.data().createdAt.toDate()).fromNow(),
      //       show: false,
      //       supportBtnState: false,
      //       isUserSupported: state.userInfo && selectedComplaintWithoutComments.data().supportedUsers ? (selectedComplaintWithoutComments.data().supportedUsers.includes(state.userInfo.id) ? true : false) : false,
      //       comments: selectedComplaintComments
      //     }
      //     commit('setSelectedComplaint', [selectedComplaintWithComments]);
      //     //state.uiConfigs.showSpinner = false
      //
      //     return true
      //   })
      //   .catch((err) => {
      //     console.error("Error writing document: ", err);
      //     //state.uiConfigs.showSpinner = false
      //
      //     return false
      //   })

    },
    async addComplaintComment({commit, state}, payload){
      localStorage.removeItem('selectedComplaintURI');
      const self = this
      const getComplaintCommentDoc = await complaintsRef
        .doc(state.selectedComplaint[0].id)
        .collection("comments")
        .doc()

      const complaintCommentAddScheme = {
        commentText: payload,
        userInfo: {
          ...state.userInfo
        },
        id: getComplaintCommentDoc.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        status: 1
      }

      try {
        const res = await getComplaintCommentDoc.set(complaintCommentAddScheme)
        console.log("Comment has added")
        //this.dispatch('fetchSelectedComplaint',state.selectedComplaint[0].id)
        //const res2 = await this.dispatch("updateComplaintCommentStat", state.selectedComplaint[0].id)
        //setTimeout(() => self.dispatch('fetchSelectedComplaint', state.selectedComplaint[0].id), 1500)

        this._vm.$sleeper(1500)

        return true
      }catch (e) {
        console.error("Error writing document: ", e);
        return false
      }

      // getComplaintCommentDoc.set(complaintCommentAddScheme)
      //  .then( () => {
      //      console.log("Comment has added")
      //     return true
      // })
      //  .catch( (e) => {
      //      console.error("Error writing document: ", e);
      //     return false
      // })

    },
    async addComplaint({ state, commit }, formData) {

      const getDoc = await complaintsRef.doc();
      if(!getDoc)
        return false

      //console.log('result id si: ', getDoc.id)
      //console.log('add complete oncesi form data json sringfied', state.complaintFormData)

      const pureFormData = state.complaintFormData
      const jsonFormatFormData = JSON.parse(pureFormData)

      //console.log( jsonFormatFormData.title)

      const complaintTitle2seoUrl = await this.dispatch('toSeoUrl', jsonFormatFormData.companyInfo.name + " " + jsonFormatFormData.title)

      console.log('add complete oncesi form data json parsed', jsonFormatFormData)

      var retrievedObject = localStorage.getItem('userInfo');
      var userInfo = JSON.parse(retrievedObject)

      console.log('local storage userInfo:', userInfo)

      const complaintAddScheme = {
        ...jsonFormatFormData,
        userInfo: {
          ...userInfo
        },
        id: getDoc.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        img: "",
        stats: {
          comment: 0,
          rating: 0,
          support: 0,
          view: 0
        },      
        status: 0,
        seoUrl : complaintTitle2seoUrl + '-_-' + getDoc.id,
        supportedUsers: [],
        hasComments: false
      }      

      const res = await getDoc.set(complaintAddScheme)
      window.localStorage.removeItem('complaintFormData')

      // try {
      //   const getDoc2 = await companiesRef.doc(jsonFormatFormData.companyInfo.id)
      //
      //   if(!getDoc2)
      //     return false
      //
      //   const updateComplaintStat = {
      //     stats: {
      //       complaints: firebase.firestore.FieldValue.increment(1)
      //     }
      //   }
      //
      //   const res2 = await getDoc2.set(updateComplaintStat,{ merge: true })
      //
      //   //this.dispatch('reDrawMasonUI')
      //
      //   return true
      // }catch (e) {
      //   console.log(e)
      // }

      return true

    },
    updateComplaintFormDataCompanyId({commit}, payload){
      commit('mutateComplaintFormDataCompanyId', payload)

    },
    updateComplaintFormData({commit}, payload){
      if(!payload) return
      //const bfrcmt = payload
      // console.log('updateComplaintFormData vuex', payload)
      commit('setComplaintFormData', payload)
    },
    updateUserInfo({commit, state}, payload){
      commit('setUserInfo', payload)
      //state.isAuthDone = true
      commit('updateAuthStatus', true);

      state.uiConfigs.showSpinner = false

    },
    async signAnon(){
      console.log('signAnon')
      // firebase.auth().signInAnonymously().catch(function(error) {
      //   // Handle Errors here.
      //   var errorCode = error.code;
      //   var errorMessage = error.message;
      //   // ...
      // });

      const res = await firebase.auth().signInAnonymously()
      console.log('res', res)
      localStorage.setItem('login', true)

      let user = res.user

      if (!user){
        return false
      }

      if (user.user) {
        user = user.user;
      }

      const createdUserData = await this.dispatch("createUserPofile", user)

      console.log('new anon user created', createdUserData)
      localStorage.setItem('userInfo', JSON.stringify(createdUserData));
      this.dispatch("updateUserInfo", createdUserData)


      return true
    },
    async signOut({commit, state}) {
      // commit('updateAuthStatus', false);

      firebase.auth().signOut();
      //this.dispatch("updateUserInfo", null)



      //Auto sign-in for returning users is enabled by default except when prompt is
      //not 'none' in the Google provider custom parameters. To manually disable:
      //ui.disableAutoSignIn();
      ui.reset()
      //ui.start('#firebaseui-auth-container', state.firebaseUIconfigs)

      this.dispatch('loginUIinit')

      state.uiConfigs.loginDialog = false
      state.uiConfigs.menuDrawer = false

      localStorage.removeItem('login')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('selectedComplaintURI')
      localStorage.removeItem('pendingAddComplaint')
      //localStorage.pendingAddComplaint = false
      //this.dispatch('fetchComplaints')
    },
    async deleteComplaint({commit, state}, id) {
      console.log(id)
      const self = this

      // const getComplaintCommentDoc = await complaintsRef
      //   .doc(id)
      //   .collection("comments")
      //   .get()
      //
      // console.log(getComplaintCommentDoc)
      // const res = await complaintsRef.doc(id).delete();

      // res ? console.log('deleted') : console.log(' not deleted') 

      // complaintsRef.doc(id).delete().then(async function() {
      //   // if(!getComplaintCommentDoc.empty){
      //   //   const getComplaintCommentDoc2 = await complaintsRef
      //   //     .doc(id)
      //   //     .collection("comments")
      //   //     .doc()
      //   //     .delete()
      //   // }
      //   console.log("Document successfully deleted!");
      //   self.dispatch('fetchComplaints')
      //   // self.dispatch('reDrawMasonUI')
      //
      // }).catch(function(error) {
      //     console.error("Error removing document: ", error);
      // });


      // const _path = 'complaints/' + id + '/comments'
      //
      // var deleteFn = functions.httpsCallable('recursiveDelete');
      // deleteFn({ path: _path })
      //   .then(function(result) {
      //     console.log('Delete success: ' + JSON.stringify(result));
      //     self.dispatch('fetchComplaints')
      //
      //   })
      //   .catch(function(err) {
      //     console.log('Delete failed, see console,');
      //     console.warn(err);
      //   });

      const getDoc = await complaintsRef.doc(id)

      if(!getDoc)
        return false

      const updateComplaintStatus = {
        status: -1
      }

      const res = await getDoc.set(updateComplaintStatus,{ merge: true })
      //self.dispatch('fetchComplaints')
      return true


    },
    async deleteComplaintComment({commit, state}, id) {
      console.log(id)
      const self = this

      // const getComplaintCommentDoc = await complaintsRef
      //   .doc(id)
      //   .collection("comments")
      //   .get()
      //
      // console.log(getComplaintCommentDoc)
      // const res = await complaintsRef.doc(id).delete();

      // res ? console.log('deleted') : console.log(' not deleted')
      const getComplaintCommentDoc = await complaintsRef
        .doc(state.selectedComplaint[0].id)
        .collection("comments")
        .doc(id)
        .delete().then( function() {
          // if(!getComplaintCommentDoc.empty){
          //   const getComplaintCommentDoc2 = await complaintsRef
          //     .doc(id)
          //     .collection("comments")
          //     .doc()
          //     .delete()
          // }
          console.log("comment successfully deleted!");
          setTimeout(() => self.dispatch('fetchSelectedComplaint',state.selectedComplaint[0].id), 1500)

          // self.dispatch('reDrawMasonUI')

        }).catch(function(error) {
          console.error("Error removing document: ", error);
        });
    },
    toSeoUrl({commit, state}, url) {
      console.log(url)
      if (url)
        return url.toString()               // Convert to string
            .normalize('NFD')               // Change diacritics
            .replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
            .replace(/\s+/g,'-')            // Change whitespace to dashes
            .toLowerCase()                  // Change to lowercase
            .replace(/&/g,'-and-')          // Replace ampersand
            .replace(/[^a-z0-9-]/g,'')     // Remove anything that is not a letter, number or dash
            .replace(/-+/g,'-')             // Remove duplicate dashes
            .replace(/^-*/,'')              // Remove starting dashes
            .replace(/-*$/,'');             // Remove trailing dashes
    },
    slugify({commit}, string) {
      const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
      const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
      const p = new RegExp(a.split('').join('|'), 'g')
    
      return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
    },
    async updateComplaintSupport({commit, state}, statInfo){
      let plusOrMin = 0
      statInfo.userSupport ? plusOrMin = 1 : plusOrMin = -1

      console.log('id, btStt, plusOrMin', statInfo.id, statInfo.userSupport, plusOrMin)

      const getDoc = await complaintsRef.doc(statInfo.id)

      if(!getDoc)
        return false

      const updateComplaintSupportSchema = {
        stats: {
          support: firebase.firestore.FieldValue.increment(+plusOrMin)
        },
        supportedUsers: statInfo.userSupport ? firebase.firestore.FieldValue.arrayUnion(state.userInfo.id) : firebase.firestore.FieldValue.arrayRemove(state.userInfo.id)
      }

      const res = await getDoc.set(updateComplaintSupportSchema,{ merge: true })

      return true 
    },
    async updateComplaintCommentStat({commit, state}, id){
      const getDoc = await complaintsRef.doc(id)

      if(!getDoc)
        return false

      const updateComplaintSupportSchema = {
        stats: {
          comment: firebase.firestore.FieldValue.increment(1)
        }
      }

      const res = await getDoc.set(updateComplaintSupportSchema,{ merge: true })

      return true
    },
    async loginUIinit({ commit, state }) {
      const res = await this.dispatch('getUiConfig')
      ui.start('#firebaseui-auth-container', res)
    },
    async createUserPofile({ commit, state }, user) {
      const roleCheck = await user.getIdTokenResult(true) // 1
      const createdAt = firebase.firestore.FieldValue.serverTimestamp(),
        data = {
          id: user.uid,
          displayName: user.displayName || 'Anonsymous',
          email: user.email || 'no-email@email.com',
          emailVerified: user.emailVerified,
          photoURL: user.photoURL || require('@/assets/img/profile/avatars/anon.jpg'),
          role: roleCheck.claims.role || 'user',
          accessLevel: roleCheck.claims.accessLevel || 1,
          isAnonymous: user.isAnonymous,
          createdAt: createdAt,
          stats: {
            complaints: 0
          }
        },
        res = await usersRef.doc(user.uid).set(data);

      return data;
    },
    async editUserInfo({commit, state}, payload){
      console.log(payload)

      const res = await usersRef.doc(state.userInfo.id).set(payload, { merge: true });

      var retrievedObject = localStorage.getItem('userInfo');
      const userInfo = JSON.parse(retrievedObject)
      userInfo.displayName = payload.displayName
      userInfo.photoURL = payload.photoURL

      localStorage.setItem('userInfo', JSON.stringify(userInfo));


      this.dispatch('updateUserInfo', userInfo)

      return true

    },
    async getUiConfig({state}) {
      //localStorage.selectedComplaintURI = null
        const _self = this
        return {
            signInSuccessUrl: localStorage.selectedComplaintURI ? '/#/complaint/' + localStorage.selectedComplaintURI : '#',
            //signInSuccessUrl: '/',
            //signInFlow: "popup",

            "autoUpgradeAnonymousUsers": true,
            'callbacks': {
                'signInSuccessWithAuthResult': async function(authResult, redirectUrl) {
                    console.log('signInSuccessWithAuthResult =>>>>>>  ' , authResult);
                    localStorage.removeItem('selectedComplaintURI')
                    localStorage.setItem('login', true)


                    let user = authResult.user
                    if (user.user) {
                    /* eslint-disable */
                    user = user.user;
                    /* eslint-enable */
                    }


                    console.log('uid is: ', user.uid)


                    //for admin custom claims need to login dobule for granting admin role
                    // users doc
                    const getUser = usersRef.doc(user.uid)

                    getUser.get()
                      .then(async (doc) =>  {
                        if (doc.exists) {
                          console.log("user exists and data: ",doc.data())
                          localStorage.setItem('userInfo', JSON.stringify(doc.data()));
                          _self.dispatch("updateUserInfo", doc.data())
                        } else {
                          //const createdUserData = await createUserPofile(user.uid)
                          const createdUserData = await _self.dispatch("createUserPofile", user)

                          console.log('new user created', createdUserData)
                          localStorage.setItem('userInfo', JSON.stringify(createdUserData));
                          _self.dispatch("updateUserInfo", createdUserData)
                        }


                        _self.dispatch("getFCMtoken")

                        if (localStorage.pendingAddComplaint) {
                          console.log('pending adding coomplate entered with status :', localStorage.pendingAddComplaint)
                          const res = await _self.dispatch("addComplaint")
                          console.log('add complaint result :', res);
                          if(res){
                            _self.dispatch("fetchComplaints")
                            state.uiConfigs.addComplaintForm = false
                            state.uiConfigs.snackbar = true
                            localStorage.removeItem('pendingAddComplaint')
                          }
                        }

                        state.uiConfigs.loginDialog = false
                        state.uiConfigs.overlay = false
                        localStorage.setItem('pendingAddComplaintCommentFirstBack', true)



                      });



                    return false
                    
                },
                'signInFailure': function(error) {
                    // Some unrecoverable error occurred during sign-in.
                    // Return a promise when error handling is completed and FirebaseUI
                    // will reset, clearing any UI. This commonly occurs for error code
                    // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
                    // occurs. Check below for more details on this.
                    console.log(error);
                    //return handleUIError(error);
                },
                'uiShown': function() {
                    // The widget is rendered.
                    // Hide the loader.
                    
                //document.getElementById('loader').style.display = 'none';
                }
            },
            //signInFlow: 'popup',
            //'autoUpgradeAnonymousUsers': true,
            'signInOptions': [
              // List of OAuth providers supported.
              firebase.auth.GoogleAuthProvider.PROVIDER_ID,

              firebase.auth.FacebookAuthProvider.PROVIDER_ID,
              // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
              {
                  provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                  // Use email link authentication and do not require password.
                  // Note this setting affects new users only.
                  // For pre-existing users, they will still be prompted to provide their
                  // passwords on sign-in.
                  //signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
                  signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,

                  // Allow the user the ability to complete sign-in cross device, including
                  // the mobile apps specified in the ActionCodeSettings object below.
                  requireDisplayName: true,
                  forceSameDevice: true
                  // Used to define the optional firebase.auth.ActionCodeSettings if
                  // additional state needs to be passed along request and whether to open
                  // the link in a mobile app if it is installed.
                  // emailLinkSignIn: function() {
                  //   return {
                  //     // Additional state showPromo=1234 can be retrieved from URL on
                  //     // sign-in completion in signInSuccess callback by checking
                  //     // window.location.href.
                  //     url: 'http://localhost:5000/?showPromo=1234',
                  //     // Custom FDL domain.
                  //     dynamicLinkDomain: 'friendlychatbaha.page.link',
                  //     // Always true for email link sign-in.
                  //     handleCodeInApp: true,
                  //     // Whether to handle link in iOS app if installed.
                  //   };
                  // }
              },
              // {
              //   provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
              //   // Invisible reCAPTCHA with image challenge and bottom left badge.
              //   recaptchaParameters: {
              //     type: 'image',
              //     size: 'normal',
              //     badge: 'inline'
              //   },
              //   defaultCountry: 'CA',
              //   defaultNationalNumber: '1',
              //   loginHint: '+16472248744',
              //   blacklistedCountries: ['US', '+44']
              // },
              firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
            ],
            // Terms of service url.
            'tosUrl': 'https://www.google.com',
            // Privacy policy url.
            'privacyPolicyUrl': function() {
            window.location.assign('https://www.google.com');
            },
            'credentialHelper': firebaseui.auth.CredentialHelper.GOOGLE_YOLO 
        }
    },
    async shareDialog({ commit, state }, payload) {
      //alert('burda')
      const subDir = 'complaint/'

      const url_ = payload || state.selectedComplaint[0].seoUrl

      const shareData = {
        title: state.selectedComplaint.title,
        text: state.selectedComplaint.detail,
        url: state.appDomain + subDir + url_
      }

      console.log('share object', shareData);
      //alert(shareData.toString());
      
      // const btn = document.querySelector('button');
      // const resultPara = document.querySelector('.result');
      
      // Must be triggered some kind of "user activation"
      if (navigator.share && state.uiConfigs.isCordova) {
        // Web Share API is supported
        try {
          await navigator.share(shareData)
        } catch (err) {
          console.log(err)
          alert('nav share', err)
        }
      } else {
        // this is the complete list of currently supported params you can pass to the plugin (all optional)
          var options = {
            message: shareData.text, // not supported on some apps (Facebook, Instagram)
            subject: shareData.title, // fi. for email
            files: ['', ''], // an array of filenames either locally or remotely
            url: shareData.url,
            chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
            appPackageName: 'com.complaints.app', // Android only, you can provide id of the App you want to share with
            iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
          };

          var onSuccess = function(result) {
            console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
            console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
          };

          var onError = function(msg) {
            console.log("Sharing failed with message: " + msg);
          };

          window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
      }


    },
    getFCMtoken({commit, state}) {
      if (state.uiConfigs.isCordova) {
          console.log('cordovaaaaaaaaaaaaaaaaaaa');
              // eslint-disable-next-line no-undef
          FirebasePlugin.getToken(function(fcmToken) {
            console.log('cordova and fcm and uid', fcmToken, firebase.auth().currentUser.uid);
            fcmTokensRef.doc(fcmToken)
            .set({uid: firebase.auth().currentUser.uid},{ merge: true });
          }, function(error) {
              console.error(error);
              //alert(error)
          });
      }
      else {
        //this.dispatch("saveMessagingDeviceToken")
      }


    },
    saveMessagingDeviceToken() {
      console.log('burdaaaaaaaa')
      const self = this
      firebase.messaging().getToken({vapidKey: "BCE44rwDOJR2i5yv9O2xTOHQXlWIJaDilgnkGijqKeSomjqZU6oPAYTLjaKI_3sMUhlgp8BswzqVTWXOTUDHYSI"}).then(function(currentToken) {
        if (currentToken) {
          console.log('Got FCM device token:', currentToken);
          // Saving the Device Token to the datastore.
          fcmTokensRef.doc(currentToken)
              .set({uid: firebase.auth().currentUser.uid});
        } else {
          // Need to request permissions to show notifications.
          self.dispatch("requestNotificationsPermissions")

        }
      }).catch(function(error){
        console.error('Unable to get messaging token.', error);
      });
    },
    // Requests permissions to show notifications.
    // Requests permission to show notifications.
    requestNotificationsPermissions() {
      console.log('burdaaaa222aaaa')
      const self = this


      console.log('Requesting notifications permission...');
      firebase.messaging().requestPermission().then(function() {
        // Notification permission granted.
        self.dispatch("saveMessagingDeviceToken")
      }).catch(function(error) {
        firebase.error('Unable to get permission to notify.', error);
      });

    },
    initFirebaseAuth({ commit, state }){
      const self = this
      //state.uiConfigs.showSpinner = true

      firebase.auth().onAuthStateChanged(async (_user) => {


          console.log('on auth state changed', _user)
          let user = _user
          if(localStorage.login == 'true'){ //firebase ui login dublicate login issue
            localStorage.removeItem('login')
            return
          }

          if (!user) {
            self.dispatch('updateUserInfo', null)
            return
          }
          else{
            var retrievedObject = localStorage.getItem('userInfo');
            const userInfo = JSON.parse(retrievedObject)
            self.dispatch('updateUserInfo', userInfo)

          }

        });



    },
    async addCompany({commit}, payload){

      // if(payload.logo != null){
      //   console.log('girdi2')
      //   payload.logo = await this._vm.$toBase64(payload.logo)
      //   console.log(payload.logo);
      // }

      const getDoc = await companiesRef.doc();
      if(!getDoc)
        return false

      const companyName2seoUrl = await this.dispatch('toSeoUrl', payload.name )

      const companyAddScheme = {
        ...payload,
        id: getDoc.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        stats: {
          complaints:0,
          satisfaction:0,
          score:0
        },
        status: 0,
        seoUrl : companyName2seoUrl
      }

      try {
        const res = await getDoc.set(companyAddScheme)
        console.log("Company has added")
        return true
      }catch (e) {
        console.error("Error writing document: ", e);
        return false
      }

    },
    reDrawMasonUI(){
      //setTimeout(() => this.$redrawVueMasonry, 5000);
      const self = this

      setTimeout(() => {
        self.$redrawVueMasonry()
      }, 6000)

      console.log('redrawed in vuex')
    },
    grantModRole({commit}, payload){
      var res = firebase.functions().httpsCallable('grantDenyModerator');
      res({ email: payload.email, grantType: payload.grantType })
        .then((result) => {
          // Read result of the Cloud Function.
          //var sanitizedMessage = result.data.text;
          alert(result.data.result)
        });
    }
//     uploadFile({commit}, payload){
//       var file = payload
//       console.log(file);
// //dynamically set reference to the file name
//       var thisRef = storageRef.child(file.name);
//
//       //put request upload file to firebase storage
//       thisRef.put(file).then(function(snapshot) {
//         console.log('Uploaded a blob or file!');
//         alert("File Uploaded")
//
//       });
//     }


  },
  modules: {},
  getters: {
    getComplaintFormData: state => state.complaintFormData,
    getAllComplaints: state => state.complaints,
    getSelectedComplaint: state => state.selectedComplaint,
    getSelectedCompanyWithComplaints: state => state.selectedCompanyWithComplaints,
    getUserComplaints: state => state.userComplaints,
    getAllCompanies: state => state.companies,
    getUserInfo: state => state.userInfo,
    isAuthDone: state => state.isAuthDone

  }
});


