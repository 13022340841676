<template>
    <v-card
        class="mx-auto mb-3"
        max-width="344"
        outlined
        link
        :to="{ name: 'Company', params: { name: item.name, seoUrl: item.seoUrl, id: item.id, logo: item.logo, field: item.field }}"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            {{ item.website }}
          </div>
          <v-list-item-title class="headline mb-1">
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ item.field }}</v-list-item-subtitle>
        <v-rating
          v-model="getRandomInt"
          background-color="warning"
          color="warning"
          length="5"
          readonly
          dense
          size="20"
        ></v-rating>
        </v-list-item-content>

        <router-link :to="{ name: 'Company', params: { name: item.name, seoUrl: item.seoUrl, id: item.id, logo: item.logo, field: item.field }}">
          <v-list-item-avatar
              tile
              size="80"
              color="white"

          >
              <v-img
                  v-if='item.logo'
                  :src='item.logo'
                  contain
                  class='elevation-6 companies-card-img'
              ></v-img>
          </v-list-item-avatar>
        </router-link>

      </v-list-item>

      <v-card-actions>
        <v-btn
            outlined
            rounded
            text
            block
        >
          {{item.stats.complaints}} Complaints
        </v-btn>
<!--        <v-btn-->
<!--            outlined-->
<!--            rounded-->
<!--            text-->
<!--        >-->
<!--          {{item.stats.complaints}} Satisfaction-->
<!--        </v-btn>-->
      </v-card-actions>
    </v-card>
</template>

<script>
export default {
  name: 'CompanyCard',
  props: ['item'],
  data: () => ({
    rating: 3
  }),
  async mounted() {
    await this.$nextTick()

    //this.repaint();
  },
  computed:{
    getRandomInt() {
            var min = Math.ceil(2);
            var max = Math.floor(5);
            return Math.floor(Math.random() * (max - min + 1)) + min
    }
  },
  methods: {
    // repaint() {
    //   //setTimeout(() => this.$redrawVueMasonry(), 1500);
    // }
    
  }

};
</script>

<style>
.v-image__image--cover {
  /*background-size: contain !important;*/
}
</style>