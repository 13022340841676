<template>
  <v-footer
      fixed
      bottom
      id='addComplaintBox'
      class='add-comment-main'
      color='gray lighten-5'

  >
<!--    :prepend-icon="commentIcon ? 'mdi-account-circle' : ''"-->

    <v-textarea
        v-model="message"
        append-outer-icon="mdi-send"
        rows='0'
        counter='250'
        auto-grow
        dense
        light
        filled
        solo
        flat
        full-width
        color='purple'
        :placeholder='placeHolder'
        @click:append-outer="sendMessage"
        @focus="onFocusWorks(); placeHolder=''"
        @blur="placeHolder='Add a comment'"
        background-color='white'
        shaped
        :loading='loader'
    >
      <template v-slot:prepend>
        <v-avatar
          size='32'
        >
          <div v-if='getUserInfo'>
            <img
                :src="getUserInfo.photoURL"
                :alt="getUserInfo.displayName"
                style='width: 35px; height: 35px;'
            >
          </div>
          <v-icon v-else>
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </template>

    </v-textarea>
  </v-footer>


</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data: () => ({
    message: '',
    placeHolder: 'Add a comment',
    commentIcon: true,
    detailRows: 1,
    loader: false
  }),
  computed: {
    ...mapGetters(['getSelectedComplaint','getUserInfo']),
    ...mapState(['userInfo', 'uiConfigs', 'appDomain']),
    pageHeight () {
      return document.body.scrollHeight
    }
  },
  methods: {
    ...mapActions(['addComplaintComment', 'fetchSelectedComplaint']),
    async sendMessage () {
      const self = this
      console.log('comment adding sending: ', this.message)
      this.loader = true
      this.uiConfigs.showSpinner = true

      const res = await this.addComplaintComment(this.message)
      console.log('is complaint comment added:', res)
      if(res){
        this.uiConfigs.showSpinner = false


        this.clearMessage()
        const res2 = await this.fetchSelectedComplaint(this.getSelectedComplaint[0].id)
        res2 ? await this.$vuetify.goTo(this.pageHeight, { duration: 0 }): false
        res2 ? this.loader = false : ''

      }
    },
    clearMessage () {
      this.message = ''
    },
    onFocusWorks(){
      this.$vuetify.goTo(0, { duration: 50 });
      !this.userInfo ? this.uiConfigs.loginDialog = true : false;
      localStorage.selectedComplaintURI = this.getSelectedComplaint[0].seoUrl;
    }
  }
}
</script>
<style>

.add-comment-main{
  border-top: 1px solid #8080801a;
  z-index: 9;
}
.v-application--is-ltr .add-comment-main .v-text-field .v-counter{
    font-size: 9px;
    min-height: 9px;
    line-height: 9px;
  }
.add-comment-main .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: -6px;
}

.add-comment-main .v-icon.v-icon{
  font-size: 32px;
}

.add-comment-main .v-input__append-outer{
  margin-top: 19px;
}
</style>