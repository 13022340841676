<template>
<div>
  <v-container class='mt-5' style='background-color: white !important;'>
    <h3>Grant Moderator Role To User</h3>
    <v-row
        justify="space-between"
    >
      <v-col
          cols="12"
          md="4"
      >

        <v-text-field
            v-model="email"
            label="E-mail"
            required
        ></v-text-field>
        <v-btn
            color="error"
            class="mr-10"
            @click="grantOrDeny('deny')"
        >
          Deny
        </v-btn>

        <v-btn
            color="success"
            @click="grantOrDeny('grant')"
        >
          Grant
        </v-btn>

      </v-col>
    </v-row>

  </v-container>

</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'GrantModeratorRole',
  data: () => ({
    email: ""
  }),
  methods: {
    ...mapActions(['grantModRole']),
    grantOrDeny(type){
      this.grantModRole({email: this.email, grantType: type })
      this.reset()
    },
    reset () {
      this.email = ''
    }
  }
};
</script>

<style scoped>

</style>