var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:['complaint-card ', _vm.sub ? 'mb-1 subComplaintCard' : 'mb-0 pb-0'],attrs:{"elevation":_vm.sub ? 0 : 1,"flat":_vm.sub,"tile":_vm.sub}},[_c('router-link',{attrs:{"to":{ name: 'Company', params: { name: _vm.item.companyInfo.name, seoUrl: _vm.item.companyInfo.seoUrl, id: _vm.item.companyInfo.id, logo: _vm.item.companyInfo.logo, field: _vm.item.companyInfo.field }}}},[(_vm.item.companyInfo.logo)?_c('div',{style:({
            right: '0',
            backgroundImage: 'url(' + _vm.item.companyInfo.logo + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '40px',
            position: 'absolute',
            height: '40px',
            opacity: '15%',
            borderRadius: '0px 0px 0px 72%',
            backgroundColor: '#80808075'
          })}):_vm._e()]),(_vm.item.img)?_c('v-img',{attrs:{"src":_vm.item.img,"width":"100%"}}):_vm._e(),_c('v-card-title',{staticClass:"complaint-title text-capitalize"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),(_vm.item.userInfo.displayName)?_c('v-card-subtitle',{staticClass:"pt-2",staticStyle:{"zoom":"0.9"}},[_c('v-avatar',{staticStyle:{"vertical-align":"sub"},attrs:{"size":"20"}},[(_vm.item.userInfo.photoURL)?_c('v-img',{staticClass:"elevation-6",attrs:{"src":_vm.item.userInfo.photoURL}}):_vm._e()],1),_vm._v(" "+_vm._s(_vm._f("truncateComplaintDetail")(_vm.item.userInfo.displayName,20, '...'))+" - "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.item.fromNow))])],1):_vm._e(),(_vm.item.detail)?_c('v-card-text',{staticClass:"text--primary complaint-detail-text"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.show || _vm.item.detail.length < 249),expression:"item.show || item.detail.length < 249"}],on:{"click":function($event){_vm.item.show = false;}}},[_vm._v(" "+_vm._s(_vm.item.detail)+" "),(_vm.item.detail.length > 249)?_c('v-icon',[_vm._v(_vm._s(_vm.item.show ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.show && _vm.item.detail.length > 249),expression:"!item.show && item.detail.length > 249"}],on:{"click":function($event){_vm.item.show = true;}}},[_vm._v(" "+_vm._s(_vm._f("truncateComplaintDetail")(_vm.item.detail,250, '...'))+" "),_c('v-btn',{attrs:{"dense":"","small":"","text":""}},[_vm._v("Read More "),_c('v-icon',[_vm._v(_vm._s(_vm.item.show ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1)]):_vm._e(),_c('v-card-actions',[_c('v-container',{staticClass:"py-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":_vm.item.isUserSupported ? 'deep-purple accent-4': 'grey',"icon":"","light":""},on:{"click":function($event){$event.preventDefault();(_vm.loader = 'loading'),
                    (_vm.item.isUserSupported = !_vm.item.isUserSupported),
                    (_vm.item.isUserSupported ? _vm.item.stats.support++ : _vm.item.stats.support--),
                    _vm.setUpdateState(_vm.item.id, _vm.item.isUserSupported)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"large":""}},[_vm._v("mdi-arrow-up-bold")]),_vm._v(" "+_vm._s(_vm.item.stats.support)+" ")],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"myClass",attrs:{"to":{ name: 'Complaint', params: { id: _vm.item.id, title: _vm.item.title, seoUrl: _vm.item.seoUrl, directRoute: true }},"color":"grey","icon":"","light":""}},[_c('v-icon',{staticClass:"mr-1 pt-1"},[_vm._v("mdi-message-text")]),_vm._v(" "+_vm._s(_vm.item.stats.comment)+" ")],1)],1),_c('v-col',{attrs:{"cols":""}},[(_vm.uiConfigs.isCordova)?_c('v-btn',{attrs:{"absolute":"","color":"grey","icon":"","light":"","right":""},on:{"click":function($event){$event.preventDefault();return _vm.shareDialog(_vm.item.seoUrl)}}},[_c('v-icon',[_vm._v("mdi-share-variant")])],1):_c('SocialShare',{attrs:{"url":_vm.item.seoUrl}})],1)],1)],1)],1),(!_vm.sub)?_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-7",attrs:{"icon":"","absolute":"","top":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2549879999)},[_c('v-list',[(
              _vm.getUserInfo!==null &&
              (_vm.getUserInfo.id == _vm.item.userInfo.id || _vm.getUserInfo.role === 'admin')
              )?_c('v-list-item',{on:{"click":function($event){return _vm.deleteComplaintBefore(_vm.item.id)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1):_c('v-list-item',[_c('v-list-item-title',[_vm._v("Report")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }