<template>
    <div>
        <v-navigation-drawer app v-model="uiConfigs.menuDrawer">
<!--          <template v-if="getUserInfo != null" v-slot:prepend>-->
<!--            <v-list-item two-line>-->
<!--              <v-list-item-avatar>-->
<!--                <img :src="getUserInfo.photoURL">-->
<!--              </v-list-item-avatar>-->

<!--              <v-list-item-content>-->
<!--                <v-list-item-title>{{getUserInfo.displayName}}</v-list-item-title>-->
<!--                <v-list-item-subtitle>{{getUserInfo.email}}</v-list-item-subtitle>-->

<!--              </v-list-item-content>-->
<!--            </v-list-item>-->
<!--          </template>-->
          <v-list v-if="getUserInfo != null">

              <v-list-item>
                  <v-list-item-avatar
                      width='70px'
                      height='70px'
                  >
                      <v-img  :src="getUserInfo.photoURL"></v-img>
                  </v-list-item-avatar>
              </v-list-item>

              <v-list-item @click='profileEdit = true; uiConfigs.menuDrawer = false ' link>
                  <v-list-item-content>
                      <v-list-item-title class="title">{{getUserInfo.displayName}}</v-list-item-title>

                      <v-list-item-subtitle class='' >{{getUserInfo.email}}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                      <v-icon>mdi-menu-down</v-icon>
                  </v-list-item-action>
              </v-list-item>

          </v-list>
        <v-divider></v-divider>
          <v-list
            nav
        >
          <v-list-item-group color='primary'>
            <v-list-item to='/'>
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to='/companies'>
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Companies</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if='getUserInfo' to='/my-complaints'>
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>My Complaints</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="getUserInfo && getUserInfo.role === 'admin'" to='/panel'>
                <v-list-item-icon>
                  <v-icon>mdi-application-settings</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                 <v-list-item-title>Panel</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
          <v-list>
                <v-list-item v-if="getUserInfo==null" >
                     <v-list-item-icon >
                        <v-icon color="pink">mdi-login</v-icon>
                    </v-list-item-icon>

                     <v-list-item-content>
                        <v-list-item-title @click="(uiConfigs.loginDialog = true)">Login</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-else>
                     <v-list-item-icon >
                        <v-icon color="pink">mdi-logout</v-icon>
                    </v-list-item-icon>

                     <v-list-item-content>
                        <v-list-item-title @click="signOut">Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
          </v-list>
          <v-divider class='to-bottom' style='bottom: 82px'></v-divider>
          <div class='to-bottom'>
            <v-list>
              <v-list-item >
                <v-switch
                    v-model="$vuetify.theme.dark"
                    inset
                    label="Dark Mode"
                    persistent-hint
                    bottom
                ></v-switch>
              </v-list-item>
            </v-list>
          </div>


        </v-navigation-drawer>

        <v-app-bar app color="white">
            <v-app-bar-nav-icon v-if='!searching' @click.stop="uiConfigs.menuDrawer = !uiConfigs.menuDrawer" class=""></v-app-bar-nav-icon>

            <div v-if='!searching' class="pt-1 d-flex align-center">
<!--                <v-img-->
<!--                    alt="Vuetify Logo"-->
<!--                    class="shrink mr-2"-->
<!--                    contain src="@/assets/logo.png"-->
<!--                    transition="scale-transition"-->
<!--                    width="30" />-->
              <v-img
                  alt="Vuetify Logo"
                  class=""
                  contain src="@/assets/logo2.jpg"
                  transition="scale-transition"
                  width="100" />
                <!--
            <v-img
                alt="Vuetify Name"
                class="shrink mt-1 hidden-sm-and-down"
                contain
                min-width="100"
                src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
                width="100"
            /> -->
            </div>
<!--            <v-toolbar-title v-show='!searching' class="logo-slogan">Stand Up For Your Rights</v-toolbar-title>-->
<!--            <span v-show='!searching' class="logo-slogan">Stand Up For Your Rights</span>-->
<!--            <span v-show='!searching' class="logo-slogan">Complaints Canada</span>-->
<!--            <v-toolbar-title v-show='!searching' class="">Complaints</v-toolbar-title>-->

            <v-spacer v-if='!searching'></v-spacer>

            <v-btn absolute right class='' icon @click="searching=!searching">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
<!--          <transition-->
<!--              name="custom-classes-transition"-->
<!--              enter-active-class="animated slideInRight"-->
<!--              leave-active-class="animated slideOutRight"-->
<!--          >-->
<!--                <v-text-field v-show="searching" clearable class="align-center pt-4"></v-text-field>-->
          <v-slide-x-transition origin='right'>
                <v-autocomplete
                    ref='autocomplete'
                    v-show="searching"
                    v-model="selectedCompany"
                    :loading="loading"
                    :items="getAllCompanies"
                    return-object
                    cache-items
                    hide-no-data
                    hide-details
                    flat
                    label="Search company complaints"
                    solo
                    light
                    dense
                    autocomplete="disabled"
                    autofocus
                    full-width
                    prepend-inner-icon="mdi-magnify"
                    @change='goToCompanyProfile(); focusOut()'
                    @focusout='focusOut()'

                >
                  <template v-slot:selection="{ item }" >
                    <v-list-item-avatar
                        color="purple lighten-5"
                        size="30"
                    >
                      <img
                          v-if="item.logo"
                          alt="Company Logo"
                          :src="item.logo"
                      >
                      <!-- {{ item.name.charAt(0) }} -->
                    </v-list-item-avatar>
                    <v-list-item-content style="flex: unset;">
                      <v-list-item-title v-text="item.name"></v-list-item-title>

                      <!-- <v-list-item-subtitle v-text="item.field"></v-list-item-subtitle> -->
<!--                      <v-input-->
<!--                          style="display: none;"-->
<!--                          ref="companyId"-->
<!--                          type="hidden"-->
<!--                          name="companyId"-->

<!--                          hidden-->
<!--                          :item-value="getInstantCompanyInfoFromFormData(item)"-->
<!--                      ></v-input>-->
                    </v-list-item-content>

                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar
                        color="purple lighten-5"
                        size="30"

                    >
                      <img
                          v-if="item.logo"
                          alt="Company Logo"
                          :src="item.logo"
                      >
                      <!-- {{ item.name.charAt(0) }} -->
                    </v-list-item-avatar>
                    <v-list-item-content style="flex: unset;">
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.field"></v-list-item-subtitle>
                      <!-- <input type="hidden" name="companyId" :value="item.id"> -->
                    </v-list-item-content>
                    <!-- TODO add company icons according to company fields  -->
                    <!-- <v-list-item-action>
                      <v-icon>mdi-food</v-icon>
                    </v-list-item-action> -->
                  </template>
                </v-autocomplete>
<!--            </transition>-->
          </v-slide-x-transition>

        </v-app-bar>




        <v-dialog
            transition="dialog-top-transition"
            max-width="400"
            persistent
            v-model='profileEdit'
        >
            <v-card sm='4'>
              <v-toolbar
                  color="primary"
                  dark
                  dense
                  class='mb-10'
              >Edit Profile
              </v-toolbar>

              <div v-if='getUserInfo' class="text-center">
                <croppa v-model="myCroppa"
                        :accept="'image/*'"
                        initial-size="contain"
                        :initial-image="getUserInfo.photoURL"
                        placeholder="Click to add logo"
                        :canvas-color="'#d7d7d7'"
                        :placeholder-font-size="14"
                        :prevent-white-space="true"
                        :width="250"
                        :height="250"
                        :quality="1"
                >
                </croppa>
                <v-container v-if='getUserInfo'>
                  <v-col>
                    <v-text-field
                        v-model="userInfo.displayName"
                        label="Display Name"
                        class='mr-5'
                        :rules="nameRules"
                        solo
                        prepend-icon='fa fa-user'
                        required
                        :counter="30"

                    ></v-text-field>
                  </v-col>
                </v-container>


              </div>
              <v-divider></v-divider>

              <v-card-actions class="justify-space-between">

                <v-btn
                    text
                    @click="profileEdit = false"
                >Close
                </v-btn>
                <v-btn
                    color="primary"
                    :loading='saveButtonLoader'
                    text
                    @click="beforeEditUserInfo()"
                >Save
                </v-btn>

              </v-card-actions>
            </v-card>
        </v-dialog>
      <v-snackbar
          v-model="profileEditSnack"
          timeout="3000"
      >
        Your profile has been updated!

        <template v-slot:action="{ attrs }">
          <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="uiConfigs.snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>

</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';


    export default {
        name: "Header",
        components: {
        },
        data: () => ({
          loading: false,
          searchInput: '',
          profileEdit: null,
          myCroppa: {},
          profileEditSnack: false,
          saveButtonLoader:false,
          items: [],
          nameRules: [
            v => !!v || 'Name is required',
            v => v.length <= 30 || 'Name must be less than 30 characters',
            v => v.length >= 10 || "Name must have at least 10 characters"

          ],
          search: null,
          selectedCompany: {},
          drawer: false,
          selectedItem: 0,
          searching: false,
          items2: [
            { text: 'My Files', icon: 'mdi-folder' },
            { text: 'Shared with me', icon: 'mdi-account-multiple' },
            { text: 'Starred', icon: 'mdi-star' },
            { text: 'Recent', icon: 'mdi-history' },
            { text: 'Offline', icon: 'mdi-check-circle' },
            { text: 'Uploads', icon: 'mdi-upload' },
            { text: 'Panel', icon: 'mdi-cloud-upload' }
          ],
          items3: [
            { title: 'Home', icon: 'mdi-home-city' },
            { title: 'My Account', icon: 'mdi-account' },
            { title: 'Users', icon: 'mdi-account-group-outline' }
          ]
        }),
        computed: {
            ...mapGetters(['getUserInfo', 'getAllCompanies']),
            ...mapState(['uiConfigs', 'userInfo'])
        },
        methods:{
            ...mapActions(['signOut', 'loginUIinit', 'editUserInfo']),
          goToCompanyProfile(){
              // const self = this
              // function onRouteReplaced(){
              //
              // }
              //
              // if(this.$route.name === 'Company'){
              //   this.$router.replace({ name: 'Company' }, onRouteReplaced)
              //   return
              // }

              //this.$route.name === 'Company' ? this.$router.replace({ name: 'Company' }, onRouteReplaced, onAbort?)
              console.log(this.$route.name)


            console.log(this.selectedCompany)

            this.$router.push({ name: 'Company',
              params: {
                name: this.selectedCompany.name,
                seoUrl: this.selectedCompany.seoUrl,
                id: this.selectedCompany.id,
                logo: this.selectedCompany.logo,
                field: this.selectedCompany.field
            },
              replace: false
            })

            //this.show = false
            //this.selectedCompany = ''

          },
          focusOut(){
            this.selectedCompany ? this.selectedCompany.length > 0 ? this.searching=true : this.searching=false : this.searching=false
          },
          onFocus (e) {
            this.$refs.autocomplete.isMenuActive = true  // open item list
          },
          async beforeEditUserInfo(){
            this.uiConfigs.showSpinner = true
            this.userInfo.photoURL = this.myCroppa.generateDataUrl('image/jpeg', 0.3)
            const res = await this.editUserInfo({
              "photoURL": this.getUserInfo.photoURL,
              "displayName": this.getUserInfo.displayName
            })
            this.uiConfigs.showSpinner = false
            this.profileEditSnack = true
            this.saveButtonLoader = true
            let _this = this
            setTimeout(function(){ _this.saveButtonLoader = false }, 3000);
            // this.reset()
            // this.myCroppa.remove()

          }
        },
      created() {

      },
      mounted() {
        let autocompleteInput = this.$refs.autocomplete.$refs.input

        autocompleteInput.addEventListener('focus', this.onFocus, true)

      }
    }
</script>

<style scoped>
.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
  /*display: none !important;*/
}
    .logo-slogan {
        width: 109px;
        white-space: normal;
        line-height: 19px;
        text-align: left;
        font-weight: 500;
        font-size: 17px;
        margin: 2px 0px 0px -4px;
    }

    .v-autocomplete.v-select.v-input--is-focused input {
       /*min-width: initial !important;*/
    }

.croppa-container {
  text-align: center;
  border: 1px solid gray;
}



.to-bottom{
  position: absolute;
  bottom: 0;
  width: 100%;
}


</style>