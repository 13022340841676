import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

import Vue from 'vue';
// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify/lib/framework';
import light from '@/plugins/vuetify/themeLight';
import dark from '@/plugins/vuetify/themeDark';
import minifyTheme from 'minify-css-string'
import LRU from 'lru-cache'


const themeCache = new LRU({
  max: 10,
  maxAge: 1000 * 60 * 60 // 1 hour
})

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi' || 'fa'
  },
  theme: {
    themes: { light, dark },
    options: { minifyTheme, themeCache }
  }
});
