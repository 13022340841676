<template>
<div v-if='loaded'>
  <v-app-bar app color="white">
    <!--      <router-link to="/#qBHh1jzC8H7jTD7KG1k5">Back</router-link>-->

    <v-btn icon link @click="goBack" >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-toolbar-title>Company Summary</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon  v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click='deleteComplaint(item.id)'>
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>


  </v-app-bar>
  <CompanyProfile :company='getSelectedCompanyWithComplaints' />

  <v-container class="complaints-container items" fluid>
    <div
        v-for='(complaint, index) in getSelectedCompanyWithComplaints.complaints'
        :key='complaint.id'
        :id="complaint.id"
        class='item'
    >
      <ComplaintCard
          :item="complaint"
          class=''
      />

      <!--            <span v-if="isComplaintCardLoopEnd(index)">-->
      <!--              <img :src='complaint.userInfo.photoURL' alt='' style='opacity: 0;'>-->
      <!--            </span>-->

    </div>
  </v-container>

  <v-container v-if='
  getSelectedCompanyWithComplaints.stats.complaints > 5
   && getSelectedCompanyWithComplaints.stats.complaints !== getSelectedCompanyWithComplaints.complaints.length'>
    <v-row class='justify-center'>
      <v-btn
          elevation="2"
          rounded
          class='mb-15'
          @click='fetchSelectedCompanyWithComplaints({ companyId: companyId, startAfter: lastComplaint })'
      >
        <v-icon left>mdi-cached</v-icon> Load More
      </v-btn>
    </v-row>
  </v-container>

</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CompanyProfile from '@/components/CompanyProfile.vue';
import ComplaintCard from '@/components/ComplaintCard'


export default {
  name: 'Company',
  components:{
    ComplaintCard,
    CompanyProfile
  },
  data: () => ({
    loaded: false,
    companyId: null
  }),
  watch:{
    $route (to, from){
      this.initCompany()
    }
  },
  computed: {
    ...mapGetters(['getSelectedCompanyWithComplaints']),
    lastComplaint(){
      return this.getSelectedCompanyWithComplaints.complaints[this.getSelectedCompanyWithComplaints.complaints.length - 1]
    }
  },
  methods: {
    ...mapActions(['fetchSelectedCompanyWithComplaints', 'goBack']),
    async initCompany(){
      if(this.getSelectedCompanyWithComplaints.length > 0) return
      this.companyId = this.$route.params.id || window.location.href.split('-_-')[1]
      const res = await this.fetchSelectedCompanyWithComplaints({ companyId: this.companyId, startAfter: null })
      this.loaded = true
    }
  },
  created() {
    this.initCompany()
  },
  mounted() {
  },
  destroyed() {
  }
};
</script>

<style scoped>

</style>