-<template>
    <div>
        <v-speed-dial
            v-model="fab"
            :top="top"
            :bottom="bottom"
            :right="right"
            :left="left"
            :direction="direction"
            :open-on-hover="hover"
            :transition="transition"
            absolute
            small
        >
            <template v-slot:activator>
                <v-btn
                  v-model="fab"
                  color="primary btn-fix"
                  retain-focus-on-click

                  icon
                  small
                  depressed
                >
                  <v-icon v-if="fab">
                      mdi-close
                  </v-icon>
                  <v-icon v-else>
                      mdi-share-variant
                  </v-icon>
                </v-btn>
            </template>
            <v-btn
                fab
                dark
                small
                color="green"
                :href="`https://wa.me/?text=Checkout%20this%20page.%20${urlSeo}`" target="_blank"
            >
                <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
            <v-btn
              dark
              fab
              bottom
              color="blue darken-7"
              small
              :href="`https://www.linkedin.com/shareArticle?mini=true&url=${urlSeo}`"
              target="_blank"
            >
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
            <v-btn
                fab
                dark
                small
                color="indigo"
                :href="`https://www.facebook.com/sharer/sharer.php?u=${urlSeo}`" target="_blank"
            >
                <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn
                fab
                dark
                small
                color="blue"
								:href="`https://twitter.com/intent/tweet?text=${urlSeo}`" target="_blank"
            >
                <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn
                fab
                dark
                small
                color="red"
                :href="`mailto:?subject=Awesomeness!&amp;body=Checkout this page!<a href='${urlSeo}'>${urlSeo}</a>`" target="_blank"
            >
                <v-icon>mdi-email</v-icon>
            </v-btn>
        </v-speed-dial>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

  export default {
    name: 'SocialShare',
    props:[
        'url'
    ],
    data: () => ({
      direction: 'left',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      pageUrl: 'test.com',
      transition: 'slide-x-reverse-transition'
    }),

    computed: {
      ...mapState(['appDomain']),
      urlSeo(){
        return this.appDomain + 'complaint/' + this.url
      },
      activeFab () {
        switch (this.tabs) {
          case 'one': return { class: 'purple', icon: 'account_circle' }
          case 'two': return { class: 'red', icon: 'edit' }
          case 'three': return { class: 'green', icon: 'keyboard_arrow_up' }
          default: return {}
        }
      }
    },

    watch: {
      top (val) {
        this.bottom = !val
      },
      right (val) {
        this.left = !val
      },
      bottom (val) {
        this.top = !val
      },
      left (val) {
        this.right = !val
      }
    }
  }
</script>

<style scoped>

</style>