<template>
    <v-card
        :class="['complaint-card ', sub ? 'mb-1 subComplaintCard' : 'mb-0 pb-0']"
        :elevation="sub ? 0 : 1"
        :flat="sub"
        :tile="sub"
    >
      <router-link :to="{ name: 'Company', params: { name: item.companyInfo.name, seoUrl: item.companyInfo.seoUrl, id: item.companyInfo.id, logo: item.companyInfo.logo, field: item.companyInfo.field }}">
        <div
            v-if='item.companyInfo.logo'
            :style="{
            right: '0',
            backgroundImage: 'url(' + item.companyInfo.logo + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '40px',
            position: 'absolute',
            height: '40px',
            opacity: '15%',
            borderRadius: '0px 0px 0px 72%',
            backgroundColor: '#80808075'
          }"
        >
        </div>
      </router-link>


      <v-img v-if='item.img' :src='item.img' width='100%'></v-img>

      <v-card-title class='complaint-title text-capitalize' >
        {{ item.title }}
      </v-card-title>

      <v-card-subtitle v-if='item.userInfo.displayName' class='pt-2' style='zoom:0.9'>
        <v-avatar size='20' style='vertical-align: sub;'>
          <v-img
              v-if='item.userInfo.photoURL'
              :src='item.userInfo.photoURL'
              class='elevation-6'
          ></v-img>
        </v-avatar>

        {{ item.userInfo.displayName | truncateComplaintDetail(20, '...') }} - <span
          class='text--disabled'>{{ item.fromNow }}</span>

      </v-card-subtitle>

      <v-card-text v-if='item.detail' class='text--primary complaint-detail-text'>

        <div v-show='item.show || item.detail.length < 249' @click='item.show = false; '>
          {{ item.detail }}
          <v-icon v-if='item.detail.length > 249'>{{ item.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </div>

        <div v-show='!item.show && item.detail.length > 249' @click='item.show = true; '>
          {{ item.detail | truncateComplaintDetail(250, '...') }}
          <v-btn dense small text>Read More
            <v-icon>{{ item.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </div>

      </v-card-text>
      <!-- <v-divider light></v-divider> -->
      <!-- :loading="loading"
:disabled="loading" -->
      <v-card-actions>
        <v-container class='py-0'>
          <v-row no-gutters>
            <v-col cols='3'>
              <v-btn
                  :color="item.isUserSupported ? 'deep-purple accent-4': 'grey'"
                  icon
                  light
                  @click.prevent="
                    (loader = 'loading'),
                    (item.isUserSupported = !item.isUserSupported),
                    (item.isUserSupported ? item.stats.support++ : item.stats.support--),
                    setUpdateState(item.id, item.isUserSupported)
                  "
              >
                <v-icon class='mr-1' large>mdi-arrow-up-bold</v-icon>
                {{ item.stats.support }}
              </v-btn>
            </v-col>
            <v-col cols='2'>
              <v-btn :to="{ name: 'Complaint', params: { id: item.id, title: item.title, seoUrl: item.seoUrl, directRoute: true }}" class='myClass' color='grey' icon
                     light>
                <v-icon class='mr-1 pt-1'>mdi-message-text</v-icon>
                {{ item.stats.comment }}

              </v-btn>
            </v-col>
            <v-col cols=''>
              <v-btn
                  v-if='uiConfigs.isCordova'
                  absolute
                  class=''
                  color='grey'
                  icon
                  light
                  right
                  @click.prevent='shareDialog(item.seoUrl)'
              >
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
              <SocialShare v-else :url='item.seoUrl'  />
            </v-col>
          </v-row>
        </v-container>

      </v-card-actions>
      <v-menu v-if='!sub' left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class='mt-7' icon absolute top right v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>


        <v-list>
          <v-list-item
              v-if="
              getUserInfo!==null &&
              (getUserInfo.id == item.userInfo.id || getUserInfo.role === 'admin')
              "
              @click='deleteComplaintBefore(item.id)'>
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-title>Report</v-list-item-title>
          </v-list-item>
        </v-list>


      </v-menu>









    </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SocialShare from '@/components/SocialShare.vue';


export default {
  components: {
    SocialShare
  },
  props: ['sub', 'getAllComplaints', 'item', 'whoseCard'],
  data: () => ({
    showAll: false,
    loader: null,
    loading: false,
    loading2: false,
    loading3: false,
    loading4: false,
    loading5: false
  }),
  computed: {
    ...mapState(['userInfo', 'uiConfigs']),
    ...mapGetters(['getUserInfo'])
  },
  methods: {
    ...mapActions(['deleteComplaint', 'updateComplaintSupport', 'shareDialog']),
    async setUpdateState(complaintId, userSupport) {
      console.log(this.userInfo);
      if (!this.userInfo) {
        console.log('you need to login first');
        return false;
      }

      console.log(complaintId, userSupport);
      const res = await this.updateComplaintSupport({ id: complaintId, userSupport: userSupport });

      res ? console.log('updated') : console.log('err');

    },
    async deleteComplaintBefore(id){
      switch (this.whoseCard) {
        case 'myComplaints':
          document.querySelector('#myComplaints_' + this.item.id).style.display = 'none'
          break
        case 'homeComplaints':
          document.querySelector('#homeComplaints_' + this.item.id).style.display = 'none'
          break

      }
      await this.deleteComplaint(id)
      //this.repaint()
    }
    // repaint() {
    //   //setTimeout(() => this.$redrawVueMasonry(), 2000);
    //   //this.$redrawVueMasonry()
    // },
    // repaint2() {
    //   // setTimeout(() => this.$redrawVueMasonry(), 2000);
    //   //setTimeout(() => this.$redrawVueMasonry(), 400);
    // }

  },
  filters: {
    truncateComplaintDetail: function(text, length, suffix) {
      //console.log(text, length, suffix)
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
    // supportButtonState(val){
    //     console.log("watcher: ", val)
    //     val ? this.supportButtonColor = 'blue' : this.supportButtonColor = 'grey'
    // }
    item(){

    }
  },
  mounted() {

  }
};
</script>

<style scoped>
.subComplaintCard{
  zoom: 1.1 !important;
}
.complaint-title {
  word-break: unset;
  line-height: 25px;
  font-size: 19px;
  width: 92%;
  word-break: break-word;
}

.complaint-detail-text::first-letter {
  text-transform: capitalize;
}


.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
  opacity: 0;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}

.complaint-card .v-btn--absolute.v-btn--top, .v-btn--fixed.v-btn--top {
  top: 10px;
}
.complaint-card .v-btn--absolute.v-btn--right, .v-btn--fixed.v-btn--right {
  right: 4px;
}

/* .complaint-card{

} */

.v-sheet.v-card {
  border-radius: 20px 0px 20px 20px;
}


</style>