import colors from 'vuetify/lib/util/colors'

export default {
  primary: {
    base: colors.deepPurple.accent4,
    darken1: colors.purple.darken2
  },
  secondary: colors.indigo,
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  tertiary: colors.pink.base,
  background: colors.grey.lighten3
}

//
// light: {
//   primary: colors.purple,
//     secondary: colors.grey.darken1,
//     accent: colors.shades.black,
//     error: colors.red.accent3,
//     background: colors.indigo.lighten5 // Not automatically applied
// }